import { api } from "./api";

export const storiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addStories: builder.query({
      query: () => `stories/getStories`,
      providesTags: ["getStories"],
    }),
    getStories: builder.query({
      query: () => `stories/getStories`,
      providesTags: ["getStories"],
    }),

    getStoryById: builder.query({
      query: (id) => `stories/getStoryOne/${id}`,
    }),

    getUserByParam: builder.query({
      query: ({ pageParam = 1, searchQuery = "" }) =>
        `stories/getWithPagination?page=${pageParam}&search=${searchQuery}`,
      providesTags: ["getStories"],
    }),

    updateStory: builder.mutation({
      query: ({ id, body }) => ({
        url: `stories/updateStory/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["getStories", "GetReports", "GetDoctors"],
    }),

    deleteStory: builder.mutation({
      query: (id) => ({
        url: `stories/deleteStory/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getStories"],
    }),
  }),
});

export const {
  useGetStoriesQuery,
  useGetStoryByIdQuery,
  useGetUserByParamQuery,
  useUpdateStoryMutation,
  useDeleteStoryMutation,
  useAddStoriesQuery,
} = storiesApi;
