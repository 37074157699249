import React, { useEffect, useState } from "react";
import "./style.css";
import { Space, Table, Button, message, Tooltip } from "antd";
import moment from "moment";
import { FaCreditCard } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import ModelCheck from "../../components/checkLists/modalCheck/ModelCheck";
import LayoutWrapper from "../../components/layout/Layout";
import {
  useGetStoriesQuery,
  useUpdateStoryMutation,
} from "../../redux/clientStores";
import { NumberFormat, PhoneNumberFormat } from "../../hook/NumberFormat";
import { useGetAllDoctorsQuery } from "../../redux/doctorApi";
import SearchBar from "../../components/SearchComponent";
import { capitalizeFirstLetter } from "../../hook/CapitalizeFirstLitter";
import socket from "../../socket";

// const headers = { transports: ["websocket"] };
// const socket = io("https://medme.abdujabborov.uz/", headers);

const CashierReception = () => {
  const [query, setQuery] = useState("");
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAges] = useState();
  const [doctorQuery, setDoctorQuery] = useState("");
  const [list, setList] = useState(false);
  const [modalData, setModalData] = useState(null);

  // RTK QUERIES
  const { data: stories, refetch, isLoading } = useGetStoriesQuery();
  const { data: doctor } = useGetAllDoctorsQuery();
  const [updateStory] = useUpdateStoryMutation();

  let dataStories = stories?.innerData || [];
  let allData = doctor?.data || [];

  let dataUsers = dataStories?.filter(
    (i) =>
      i?.view === false &&
      i?.online === false &&
      i?.payState !== true &&
      i?.day === moment().format("DD.MM.YYYY")
  );
  dataUsers.sort((a, b) => a.queueNumber - b.queueNumber);

  useEffect(() => {
    socket.on("new_patient", (newPatient) => refetch());
    return () => socket.off("new_patient");
  }, [refetch]);

  const updatePayState = (item, paymentType) => {
    let obj = { ...dataStories.find((i) => i._id === item.key) };
    obj.payState = true;
    obj.paymentType = paymentType; // Set paymentType
    updateStory({ id: obj?._id, body: obj })
      .then((res) => {
        if (res?.data?.success) {
          setList(true);
          setModalData(res?.data?.innerData);
          message.success(res?.data?.message);
        }
      })
      .catch((err) => message.error(err.message));
  };

  // jadval malumotlari
  const columns = [
    {
      title: "Navbati",
      dataIndex: "navbati",
      key: "navbati",
    },
    {
      title: "Bemor",
      dataIndex: "bemor",
      key: "bemor",
      render: (v) =>
        capitalizeFirstLetter(v.split(" ")[0]) +
        " " +
        capitalizeFirstLetter(v.split(" ")[1]),
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => PhoneNumberFormat(phone),
    },
    {
      title: "Yo'naltirildi",
      dataIndex: "dispatch",
      key: "dispatch",
      render: (v) => capitalizeFirstLetter(v),
    },
    {
      title: "Doktor",
      dataIndex: "doktor",
      key: "doktor",
      render: (v) =>
        capitalizeFirstLetter(v.split(" ")[0]) +
        " " +
        capitalizeFirstLetter(v.split(" ")[1]),
    },
    {
      title: "Qabul narxi",
      dataIndex: "payd",
      key: "payd",
      render: (payd) => {
        return payd === 0 || payd === undefined
          ? "Ko'rikdan so'ng aniq bo'ladi!"
          : NumberFormat(payd) + " so'm";
      },
    },
    {
      title: "Aktiv qilish",
      key: "taxrirlash",
      render: (_, record) => (
        <Space>
          <Tooltip title="Naqt pul to'lovini tanlang">
            <Button
              type="primary"
              className="btnAddMoney cashPayment"
              onClick={() => updatePayState(record, false)} // Cash payment
            >
              <GiTakeMyMoney />
            </Button>
          </Tooltip>
          <Tooltip title="Karta orqali to'lovni tanlang">
            <Button
              type="primary"
              className="btnAddMoney cardPayment"
              onClick={() => updatePayState(record, true)} // Card payment
            >
              <FaCreditCard />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  // jadval Datasi
  const data = dataUsers
    ?.filter((doc) => doc.clientFullname?.includes(query))
    ?.filter((doc) => doc.doctorIdNumber?.includes(doctorQuery))
    ?.map((client) => ({
      key: client._id,
      navbati: client.queueNumber,
      bemor: client.clientFullname,
      phone: client.clientPhone,
      dispatch: client.doctorType,
      doktor: client.doctorFullname,
      payd: client.paySumm,
    }));

  return (
    <LayoutWrapper>
      <SearchBar
        minAge={minAge}
        setMaxAges={setMaxAges}
        setMinAge={setMinAge}
        maxAge={maxAge}
        setDoctorQuery={setDoctorQuery}
        allDoctor={allData}
        data={data}
        query={query}
        setQuery={setQuery}
      />

      <Table
        rowKey={"key"}
        loading={isLoading}
        bordered={true}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      {list && (
        <ModelCheck
          data={{
            values: {
              firstname: modalData?.clientFullname.split(" ")[0],
              lastname: modalData?.clientFullname.split(" ")[1],
            },

            doctor_info: {
              phone: modalData?.doctorPhone,
              specialization: modalData?.doctorType,
              lastName: modalData.doctorFullname.split(" ")[0],
              firstName: modalData.doctorFullname.split(" ")[1],
            },
            queueNumber: modalData?.queueNumber,
            paySum: modalData?.paySumm,
          }}
          list={list}
          setList={setList}
        />
      )}
    </LayoutWrapper>
  );
};

export default CashierReception;
