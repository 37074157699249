import {
  Col,
  Checkbox,
  Button,
  Modal,
  Form,
  Input,
  Tabs,
  Row,
  Select,
  Space,
  message,
  Radio,
} from "antd";
import { useDispatch } from "react-redux";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import moment from "moment";
import OtpInput from "react-otp-input";
import { FaMale, FaFemale } from "react-icons/fa";
import { MdLocalHospital } from "react-icons/md";
import { CloseOutlined } from "@ant-design/icons";
import Selectt from "react-select";
import LayoutWrapper from "../../../components/layout/Layout";
import "./style.css";
import { NumberFormat } from "../../../hook/NumberFormat";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import {
  useCreateClientMutation,
  useGetUserByIDNumberQuery,
} from "../../../redux/clientApi";
import { useGetStoriesQuery } from "../../../redux/clientStores";
import ModelCheck from "../../../components/checkLists/modalCheck/ModelCheck";
import Once from "../onceTreatment/Once";
import axios from "../../../api";
import bad from "../../../assets/single-bed.png";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
import { showSearch, hideSearch } from "../../../redux/features/searchNav";
import AddUserToRoom from "./addUserRoom";

const Register = () => {
  const dispatch = useDispatch();
  const today = moment().format("DD.MM.YYYY");

  const month = new Date().toLocaleString("en-US", { month: "long" });
  const [form] = Form.useForm();
  const formRef = useRef();

  const [year, setYear] = useState("");
  const [phone, setPhone] = useState("");
  const [choseDoctor, setChoseDoctor] = useState("");
  const [otp, setOtp] = useState("");
  const [gender, setGender] = useState("");
  const [paySum, setPaySum] = useState(0);
  const [queueNumber, setQueueNumber] = useState(0);
  const [list, setList] = useState(null);
  const [dispatchCheck, setDispatchCheck] = useState(false);
  const [secondary, setSecondary] = useState(false);
  const [isDisabled, setIsDisabled] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [extraClass, setExtraClass] = useState(false);
  const [extraClass2, setExtraClass2] = useState(false);
  const [extraClass3, setExtraClass3] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(true);
  const [debtor, setDebtor] = useState(false);
  const [selectedExtraSpecialization, setSelectedExtraSpecialization] =
    useState(false);
  const [
    selectedExtraSpecializationState,
    setSelectedExtraSpecializationState,
  ] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [extraTreatment, setExtraTreatment] = useState([]);
  const [extraSpecialization, setExtraSpecialization] = useState([]);
  const [changeCheckboxState, setChangeCheckboxState] = useState(false);

  // RTK SOROVLAR
  // telefon raqam orqali topish
  const { data: singleUser, refetch } = useGetUserByIDNumberQuery(
    phone.replace("+998", "") || 0
  );
  useEffect(() => {
    if (phone.replace("+998", "").length === 9) {
      refetch();
    }
  }, [phone]);

  const { data: alluser } = useGetStoriesQuery();
  const { data: all_Doctor } = useGetAllDoctorsQuery();
  const [CreateNewClient] = useCreateClientMutation();

  let allUserData = alluser?.innerData || [];
  let allDoctor = useMemo(() => all_Doctor?.data || [], [all_Doctor]);
  let onlyDoctors = allDoctor?.filter((i) => i.docORrecep === "doctor");
  let buhgalter = allDoctor?.find((i) => i.docORrecep === "buhgalter");
  let doctor_info = allDoctor?.find((d) => d._id === choseDoctor);

  useEffect(() => {
    setExtraSpecialization(doctor_info?.extraSpecialization);
    setPaySum(doctor_info?.feesPerCunsaltation);
  }, [doctor_info]);

  useEffect(() => {
    setPaySum(
      selectedExtraSpecializationState
        ? selectedExtraSpecialization.price
        : doctor_info?.feesPerCunsaltation
    );
  }, [selectedExtraSpecializationState]);

  useEffect(() => {
    if (list === false) {
      setExtraTreatment([]);
      setPaySum(0);
      setChoseDoctor("");
      setIsDisabled(false);
      setSelectedExtraSpecialization(false);
      setSelectedExtraSpecializationState(false);
    }
  }, [list]);

  // addressni olish
  let headers = {
    headers: {
      authentication: `Bearer ${localStorage.token}`,
    },
  };

  // tuman
  const getDistricts = (regionID) => {
    axios
      .get(`/regions/getDistricts/${regionID}`, headers)
      .then((res) => setDistricts(res.data))
      .catch((err) => console.log(err));
  };
  // viloyat
  useEffect(() => {
    axios
      .get("/regions/getRegions", headers)
      .then((res) => setRegions(res?.data))
      .catch((err) => console.log(err));

    getDistricts(7);
  }, []);

  // MFY
  const getQuarters = (districtID) => {
    axios
      .get(`/regions/getQuarters/${districtID}`, headers)
      .then((res) => setQuarters(res.data))
      .catch((err) => console.log(err));
  };

  // bemor topilsa inputlarni toldirish
  useEffect(() => {
    if (singleUser?.data) {
      let userInfo = singleUser?.data;
      form.setFieldsValue({ firstname: userInfo?.firstname });
      form.setFieldsValue({ lastname: userInfo?.lastname });
      form.setFieldsValue({ idNumber: userInfo?.idNumber });
      form.setFieldsValue({ year: userInfo?.year });
      form.setFieldsValue({ address: userInfo?.address });
      form.setFieldsValue({ gender: userInfo?.gender });
    }
  }, [singleUser, form]);

  // doctorlarni qabulidagi bemorlar soni
  const data = [];
  for (const item of onlyDoctors) {
    let clientLength = allUserData?.filter(
      (c) =>
        c.doctorIdNumber === item.idNumber &&
        c.view !== true &&
        c.day === today &&
        c.payState === true
    )?.length;

    data.push({
      value: item._id,
      label:
        item.specialization +
        ` (${capitalizeFirstLetter(item.firstName) +
        " " +
        capitalizeFirstLetter(item.lastName)
        })  [${clientLength}]`,
    });
  }

  //------------ JINSLAR ---------------------
  const optionsWithDisabled = [
    {
      label: <FaMale style={{ fontSize: "17px", margin: "0 5px" }} />,
      value: "male",
    },
    {
      label: <FaFemale style={{ fontSize: "17px", margin: "0 5px" }} />,
      value: "female",
    },
  ];

  // Royhatga olish
  const handleFinish = async (values) => {
    setIsDisabled(true);
    let address = {
      region: values.address.region?.value || "Namangan",
      district: values.address.district?.value,
      quarter: values.address.quarter?.value,
    };

    const AllInfo = {
      idNumber: values?.idNumber?.toLowerCase(),
      firstname: values.firstname,
      lastname: values.lastname,
      phone: values.phone.replace("+998", ""),
      address,
      year: values.year,
      gender: gender,
      orderDoctorID: doctor_info?.idNumber,
      debtor,
      totalPrice: paySum,
      story: {
        ...values.extra,
        debtor,
        doctorIdNumber: doctor_info?.idNumber,
        birthday: values?.year,
        address,
        gender: gender,
        doctorPhone: doctor_info?.phone,
        clientID: singleUser?.data?.idNumber || "",
        day: today,
        month,
        clientFullname: values?.firstname + " " + values?.lastname,
        clientPhone: values?.phone.replace("+998", ""),
        doctorFullname: doctor_info?.firstName + " " + doctor_info.lastName,
        doctorType: selectedExtraSpecializationState
          ? selectedExtraSpecialization.specialization
          : doctor_info?.specialization,
        extraTreatment,
        paySumm: paySum,
        doctorPrice: doctor_info.feesPerCunsaltation,
        payState: buhgalter ? false : true,
        extraSpecialization: selectedExtraSpecializationState,
      },
    };

    await CreateNewClient(AllInfo)
      .then((res) => {
        if (res?.data?.success) {
          setQueueNumber(res?.data?.data);
          message.success(res?.data?.message);
          if (!buhgalter) {
            setModalData({ values, doctor_info });
            setList(true);
            getDistricts(7);
          } else {
            setList(false);
            setIsDisabled(false);
          }
          setDebtor(false);
          formRef?.current?.resetFields();

          return;
        }
        message.warning(res.error?.data || res?.data?.message);
        setIsDisabled(false);
      })
      .catch((err) => console.log(err));
  };

  // ------------yyy-mm-dd------------
  const handleDateChange = (event) => {
    const inputValue = event.target.value;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    dateRegex.test(inputValue)
      ? setYear(inputValue)
      : message.warning("Noto'g'ri sana formati");
  };

  // qoshimcha xizmatlanri tanlash
  const handleCheckboxChange = (checked, treatment) => {
    setExtraTreatment((prev) =>
      checked
        ? [...prev, treatment]
        : prev.filter((t) => t._id !== treatment._id)
    );
    setChangeCheckboxState(!changeCheckboxState);
  };

  // Jami narxni hisoblash qoshimcha xizmatlarniki
  useEffect(() => {
    setPaySum(
      extraTreatment.reduce((sum, treatment) => sum + treatment.price, 0)
    );
    // }, [extraTreatment]);
  }, [changeCheckboxState]);

  // const getExtraTreatment = doctor_info?.extraTreatment?.length > 0;

  let extraSpecializationTypes = ["massaj", "fizioterapiya", "analis", "uzi"];


  const receptionTreatingBtn = (
    <div className="receptionTreatingBtn">
      {buttonVisible ? (
        <Button
          type="primary"
          onClick={() => {
            setButtonVisible(false);
            dispatch(showSearch());
          }}
        >
          <img src={bad} alt="Icon" />
          Davolash
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={() => {
            setButtonVisible(true);
            dispatch(hideSearch());
          }}
        >
          <CloseOutlined /> Ortga
        </Button>
      )}
    </div>
  );

  return (
    <LayoutWrapper>
      <Tabs tabBarExtraContent={receptionTreatingBtn}>
        <Tabs.TabPane defaultActiveKey="0" tab="Ro'yhatga olish" key={0}>
          {
            buttonVisible ?

              <Form
                form={form}
                onFinish={handleFinish}
                layout="vertical"
                ref={formRef}
                className="FormApply"
                initialValues={{
                  address: {
                    region: "Namangan", // Bu yerda region uchun default value qo'yilyapti
                  },
                }}
              >
                <Row className="Row">
                  <Col className="Col-Form">
                    <Form.Item
                      label="Telefon"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Telefon raqamini kiriting",
                        },
                        {
                          message:
                            "To'g'ri telefon raqamini kiriting (+998 xx xxx-xx-xx)",
                        },
                      ]}
                    >
                      <PhoneInput
                        defaultCountry="uz"
                        value={phone}
                        onChange={(e) => e.length === 13 && setPhone(e)}
                        inputStyle={{ width: "100%" }}
                        className="PhoneInput"
                        placeholder="+998 xx xxx-xx-xx"
                      />
                    </Form.Item>
                  </Col>

                  <Col className="Col-Form">
                    <Form.Item
                      label="Ismi"
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Ismni kiriting",
                        },
                        {
                          min: 2,
                          message: "Ism kamida 2 ta belgidan iborat bo'lishi kerak",
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Ismi" />
                    </Form.Item>
                  </Col>

                  <Col className="Col-Form">
                    <Form.Item
                      label="Familiya"
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: "Familiyani kiriting",
                        },
                        {
                          min: 2,
                          message:
                            "Familiya kamida 2 ta belgidan iborat bo'lishi kerak",
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Familiyasi" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="Row">
                  <Col className="Col-Form">
                    <Form.Item
                      label="Shaxsiy raqami"
                      name="idNumber"
                      rules={[
                        {
                          required: false,
                          message: "Iltimos, shaxsiy raqamni kiriting",
                        },
                        {
                          len: 9,
                          message:
                            "Shaxsiy 2 ta harf raqam 7 raqamdan iborat bo'lishi kerak",
                        },
                      ]}
                    >
                      <OtpInput
                        inputStyle={{
                          border: "1px solid #ddd",
                          margin: "2.2px",
                          borderRadius: "5px",
                          width: "30px",
                          height: "32px",
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={9}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        placeholder="ab1234567"
                      />
                    </Form.Item>
                  </Col>

                  <Col className="Col-Form">
                    <Form.Item
                      label="Tug'ilgan sana:"
                      name="year"
                      rules={[
                        {
                          required: true,
                          message: "Iltimos, tug'ilgan sanani kiriting",
                        },
                      ]}
                    >
                      <input
                        type="date"
                        style={{ width: "100%" }}
                        id="dateInput"
                        value={year}
                        max={new Date().toISOString().split("T")[0]}
                        onChange={handleDateChange}
                        placeholder="yil/oy/kun"
                        className="DatePicer"
                      />
                    </Form.Item>
                  </Col>

                  <div className="Col-Form_Box">
                    <Col
                      className={`${extraClass ? "Col-Form col11" : "Col-Form"}`}
                    >
                      <Form.Item
                        label="Viloyat"
                        name={["address", "region"]}
                        rules={[
                          {
                            required: true,
                            message: "Viloyatni tanlang",
                          },
                        ]}
                      >
                        <Selectt
                          defaultValue={"Namangan"}
                          defaultInputValue="Namangan"
                          onMenuOpen={() => setExtraClass(true)}
                          onMenuClose={() => setExtraClass(false)}
                          onChange={(e) => getDistricts(e.regionID)}
                          options={regions}
                          placeholder="Viloyat"
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      className={`${extraClass2 ? "Col-Form col11" : "Col-Form"}`}
                    >
                      <Form.Item
                        label="Tuman"
                        name={["address", "district"]}
                        rules={[
                          {
                            required: true,
                            message: "Tumanni tanlang",
                          },
                        ]}
                      >
                        <Selectt
                          onMenuOpen={() => setExtraClass2(true)}
                          onMenuClose={() => setExtraClass2(false)}
                          onChange={(e) => getQuarters(e.districtID)}
                          options={districts}
                          placeholder="Tuman"
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      className={`${extraClass3 ? "Col-Form col11" : "Col-Form"}`}
                    >
                      <Form.Item
                        label="M.F.Y"
                        name={["address", "quarter"]}
                        rules={[
                          {
                            required: true,
                            message: "M.F.Y ni tanlang",
                          },
                        ]}
                      >
                        <Selectt
                          onMenuOpen={() => setExtraClass3(true)}
                          onMenuClose={() => setExtraClass3(false)}
                          options={quarters}
                          placeholder="M.F.Y"
                        />
                      </Form.Item>
                    </Col>
                  </div>
                </Row>

                <Row className="Row">
                  <div className="Col-Form_Box">
                    <Col width={100} className="Col-Form Col-FormCheck">
                      <Form.Item label="Doktor" name="doctor" required={true}>
                        <Select
                          // mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Doktorlar"
                          onChange={(value) => {
                            setChoseDoctor(value);
                            setSelectedExtraSpecialization(false);
                            setSelectedExtraSpecializationState(false);
                          }}
                          options={data}
                          optionrender={(option) => (
                            <Space>
                              <span role="img" aria-label={data.label}>
                                {data.data.label}
                              </span>
                              {option.label}
                            </Space>
                          )}
                        // required={true}
                        />
                      </Form.Item>
                    </Col>
                    {!doctor_info?.secondPrice ? (
                      ""
                    ) : (
                      <Form.Item
                        label={!secondary ? "Ikkilamchi" : "Birlamchi"}
                        name="analiz"
                      >
                        <div className="doctorName">
                          <Checkbox
                            className="onChecked"
                            onChange={(e) => {
                              setSecondary(e.target.checked);
                              setPaySum(
                                secondary
                                  ? doctor_info?.feesPerCunsaltation
                                  : doctor_info?.secondPrice || 0
                              );
                            }}
                          >
                            {secondary ? "Yo'q" : "Ha"}{" "}
                          </Checkbox>
                        </div>
                      </Form.Item>
                    )}
                  </div>
                  <div className="Col-Form_Box">
                    <Col className="Col-Form">
                      <Form.Item label="Bo'yi" name={["extra", "height"]}>
                        <Input type="number" placeholder="Bo'yi" />
                      </Form.Item>
                    </Col>
                    <Col className="Col-Form">
                      <Form.Item label="Vazni" name={["extra", "weight"]}>
                        <Input type="number" placeholder="Vazni" />
                      </Form.Item>
                    </Col>
                    <Col className="Col-Form">
                      <Form.Item
                        label="Tana harorati"
                        name={["extra", "temperature"]}
                      >
                        <Input type="number" placeholder="Tana harorati " />
                      </Form.Item>
                    </Col>
                  </div>
                  <Col className="Col-Form">
                    <Form.Item
                      label="Shoshilinch tarzda yuborildi"
                      name={["extra", "dispatchCheck"]}
                    >
                      <div className="doctorName">
                        <Checkbox
                          onClick={() => setDispatchCheck(!dispatchCheck)}
                          className="onChecked"
                        >
                          {dispatchCheck ? "Ha" : "Yo'q"}{" "}
                        </Checkbox>
                        {dispatchCheck && (
                          <Input
                            type="text"
                            placeholder="Malumot..."
                            className="urgent"
                          />
                        )}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="Row">
                  <Form.Item
                    name="gender"
                    label="Jinsi: Erkak/Ayol"
                    rules={[
                      {
                        required: true,
                        message: "Jinsini tanlang",
                      },
                    ]}
                  >
                    <Radio.Group
                      options={optionsWithDisabled}
                      onChange={(e) => setGender(e.target.value)}
                      value={gender}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>

                  {extraSpecialization?.length ? (
                    <div className="extraSpecializationsBtns">
                      {extraSpecialization?.map((value, index) => (
                        <Button
                          type={
                            selectedExtraSpecializationState ? "primary" : "default"
                          }
                          onClick={() => {
                            setSelectedExtraSpecializationState(
                              !selectedExtraSpecializationState
                            );
                            setSelectedExtraSpecialization(value);
                          }}
                          key={index}
                        >
                          {value.specialization}
                        </Button>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}

                  {buttonVisible && (
                    <>
                      {doctor_info && (
                        <Col className="Col-Form">
                          <Form.Item
                            label={`To'lov miqdori: ${NumberFormat(paySum)} so'm`}
                          >
                            <div className="choosedDoctors">
                              <div key={doctor_info._id}>
                                <span>{`${doctor_info.specialization} (${doctor_info.firstName} ${doctor_info.lastName})`}</span>
                                <Checkbox
                                  onChange={(e) => setDebtor(e.target.checked)}
                                />
                              </div>
                            </div>
                          </Form.Item>
                        </Col>
                      )}
                      {extraSpecializationTypes?.includes(
                        doctor_info?.type.toLowerCase()
                      ) && (
                          <Button
                            type="primary"
                            onClick={() => setIsModalVisible(true)}
                          >
                            Qo'shimcha Davolash
                          </Button>
                        )}
                      <Modal
                        title="Extra Treatment"
                        open={isModalVisible}
                        onOk={() => setIsModalVisible(false)}
                        onCancel={() => setIsModalVisible(false)}
                      >
                        {doctor_info?.extraTreatment.length > 0 ? (
                          <Form.Item
                            label={`To'lov miqdori: ${paySum.toLocaleString()} so'm`}
                          >
                            <div className="choosedDoctors">
                              {doctor_info?.extraTreatment?.map((treatment) => (
                                <div key={treatment._id}>
                                  <span>{`${treatment.name
                                    } (${treatment.price.toLocaleString()} so'm)`}</span>
                                  <Checkbox
                                    checked={extraTreatment.some(
                                      (t) => t._id === treatment._id
                                    )}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        e.target.checked,
                                        treatment
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          </Form.Item>
                        ) : null}
                      </Modal>
                    </>
                  )}

                </Row>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Row className="Row">

                    <Col className="Col-Form">
                      <button
                        disabled={isDisabled}
                        style={{
                          background: isDisabled ? "crimson" : "dodgerblue",
                          cursor: isDisabled ? "no-drop" : "pointer",
                          padding: 0,
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          justifyContent: "center",
                        }}
                        className="button"
                        type="submit"
                      >
                        <MdLocalHospital style={{ fontSize: "20px" }} />
                        Vrach qabuliga
                      </button>
                    </Col>

                  </Row>
                </div>
              </Form>
              :
              <AddUserToRoom />

          }
        </Tabs.TabPane>

        <Tabs.TabPane defaultActiveKey="1" tab="️Muolaja bo'limi" key={1}>
          <Once />
        </Tabs.TabPane>
      </Tabs>

      {list && (
        <ModelCheck
          data={{
            ...modalData,
            queueNumber,
            paySum,
          }}
          list={list}
          setList={setList}
        />
      )}
    </LayoutWrapper>
  );
};

export default Register;



