// BioKimyovi qon taxlil
export const biokimyoviyTaxliliData = [
  {
    label: "Biokimyoviy Tahlil",
    options: [
      {
        analis: 1,
        name: "АСТ+АЛТ+БИЛИРУБИН умумий",
      },
      {
        analis: 1,
        name: "КРЕАТИНИН+МОЧЕВИНА",
      },
      {
        analis: 1,
        name: "ГЕПАТИТ В+ГЕПАТИТ С",
      },
      {
        analis: 1,
        name: "РЕВМАТОИДНЫЙ ФАКТОР+С РЕАКТИВНЫЙ БЕЛОК+АСЛО",
      },
      {
        analis: 1,
        name: "Umumiy qon tahlili",
      },
      {
        analis: 1,
        name: "PTI",
      },
      {
        analis: 1,
        name: "Koaguloramma",
      },
      {
        analis: 1,
        name: "Gepatit B",
      },
      {
        analis: 1,
        name: "Gepatit C",
      },
      {
        analis: 1,
        name: "Revmatoidniy faktor ",
      },
      {
        analis: 1,
        name: "Aslo",
      },
      {
        analis: 1,
        name: "Qon guruhi-rezus faktor",
      },
      {
        analis: 1,
        name: "Umumiy oqsil",
        norma: "<3 yosh 46-70, >3 yosh 66-85",
        siBirlik: "g/l",
      },
      { analis: 1, name: "Al'bumin", norma: "35-55", siBirlik: "g/l" },
      { analis: 1, name: "Xolesterin", norma: "<5,2", siBirlik: "mmol'/l" },
      {
        analis: 1,
        name: "Triglitseridlar",
        norma: "<2,28",
        siBirlik: "mmol'/l",
      },
      { analis: 1, name: "Glukooza", norma: "3,2 - 6,1", siBirlik: "mmol'/l" },
      { analis: 1, name: "Machevina", norma: "2,5 - 8,3", siBirlik: "mmol'/l" },
      {
        analis: 1,
        name: "Kreatinin",
        norma: "E: 44 - 115 A: 44 - 97",
        siBirlik: "mmol'/l",
      },
      {
        analis: 1,
        name: "Bilirubin:Umumiy",
        norma: "3,4 - 20,5",
        siBirlik: "mmol'/l",
      },
      {
        analis: 1,
        name: "Bilirubin: bog'langan",
        norma: "0,86 - 5,3",
        siBirlik: "mmol'/l",
      },
      {
        analis: 1,
        name: "Bilirubin: erkin",
        norma: "1,7 - 17,1",
        siBirlik: "mmol'/l",
      },
      {
        analis: 1,
        name: "Alaninaminotransferaza (ALT)",
        norma: "<40",
        siBirlik: "Yed/l",
      },
      {
        analis: 1,
        name: "Aspartataminotransferaza (ACT)",
        norma: "<35",
        siBirlik: "Yed/l",
      },
      {
        analis: 1,
        name: "Laktatdegidrogenaza (LDG)",
        norma: "225-450",
        siBirlik: "Yed/l",
      },
      {
        analis: 1,
        name: "Gammaglutamiltransferaza (GGT)",
        norma: "E: 11-61 A: 9-39",
        siBirlik: "Yed/l",
      },
      { analis: 1, name: "a-amilaxa", norma: "0-220", siBirlik: "Yed/l" },
      {
        analis: 1,
        name: "Ishqoriy fosfataza (IF)",
        norma: "< 15 yosh < 644 15-17 yosh < 483",
        siBirlik: "Yed/l",
      },
      { analis: 1, name: "Kaliy", norma: "3,6-5,4", siBirlik: "mmol'/l" },
      { analis: 1, name: "Natriy", norma: "135 - 150", siBirlik: "mmol'/l" },
      { analis: 1, name: "Kal'tsiy", norma: "2,0-2,6", siBirlik: "mmol'/l" },
      { analis: 1, name: "Temir", norma: "6,6-27", siBirlik: "mmol'/l" },
      { analis: 1, name: "Fosfor", norma: "4,0-7,0", siBirlik: "mg/dl" },
      { analis: 1, name: "Xlor", norma: "98-106", siBirlik: "mmol'/l" },
      {
        analis: 1,
        name: "Urik kislota",
        norma: "E: 200-420 A: 150-380",
        siBirlik: "µmol/l",
      },
      {
        analis: 1,
        name: "Albumin/globulin nisbati",
        norma: "1,0-2,0",
        siBirlik: "",
      },
      {
        analis: 1,
        name: "Kreatin kinaza (CK)",
        norma: "38-174",
        siBirlik: "Yed/l",
      },
      {
        analis: 1,
        name: "Laktat degidrogenaza (LDH)",
        norma: "225-450",
        siBirlik: "Yed/l",
      },
      { analis: 1, name: "D-dimer", norma: "<500", siBirlik: "ng/ml" },
      {
        analis: 1,
        name: "Kreatin kinaza MB (CK-MB)",
        norma: "<25",
        siBirlik: "Yed/l",
      },
      { analis: 1, name: "Troponin I", norma: "<0,04", siBirlik: "µg/l" },
      {
        analis: 1,
        name: "C-reaktiv protein (CRP)",
        norma: "<5",
        siBirlik: "mg/l",
      },
      { analis: 1, name: "Fibrinogen", norma: "2-4", siBirlik: "g/l" },
      { analis: 1, name: "Transferrin", norma: "2,0-3,6", siBirlik: "g/l" },
      {
        analis: 1,
        name: "Homotsistein",
        norma: "5,0-15,0",
        siBirlik: "µmol/l",
      },
    ],
  },
  {
    label: "Umumiy Tahlil",
    options: [
      {
        analis: 2,
        name: "Gemoglobin (HB)",
        norma: "E: 130-160,0  A: 120-140,0",
        siBirlik: "g/l",
      },
      {
        analis: 2,
        name: "Eritrotsitlar (RBC)",
        norma: "E: 4,0-5,0  A: 3,9-4,7",
        siBirlik: "10/l",
      },
      {
        analis: 2,
        name: "Rang ko'rsatkichi",
        norma: "0,85 - 1,05",
        siBirlik: "",
      },
      {
        analis: 2,
        name: "Eritrotsitlar o'rtacha xajmi (MCV)",
        norma: "80-100",
        siBirlik: "mkm",
      },
      {
        analis: 2,
        name: "1 dona eritrotsitdagi gemoglabin miqdori (MCH)",
        norma: "30-35",
        siBirlik: "pg",
      },
      {
        analis: 2,
        name: "Eritrotsitdagi gemoglabin kontsentratsiyasi (MCHC)",
        norma: "320-360",
        siBirlik: "g/l",
      },
      {
        analis: 2,
        name: "Eritrotsitlar anizotsitozi (RDW-CV)",
        norma: "11,5-14,4",
        siBirlik: "%",
      },
      {
        analis: 2,
        name: "Gematokrit (HCT)",
        norma: "E: 35-49  A: 32-45",
        siBirlik: "%",
      },
      {
        analis: 2,
        name: "Trombotsitlar (PLT)",
        norma: "180,0 320,0",
        siBirlik: "10/l",
      },
      {
        analis: 2,
        name: "Trombotsitlar o'rtacha xajmi (MPV)",
        norma: "3,6 - 9,4",
        siBirlik: "mkm",
      },
      {
        analis: 2,
        name: "Trombotsitlar anizotsitozi (RDW)",
        norma: "1-20",
        siBirlik: "%",
      },
      {
        analis: 2,
        name: "Trombokrit (RST)",
        norma: "0,15 - 0,45",
        siBirlik: "%",
      },
      {
        analis: 2,
        name: "Leykotditlar (WBC)",
        norma: "4,0 - 9,0",
        siBirlik: "10/l",
      },
      { analis: 2, name: "Mielotsitler", norma: "-", siBirlik: "" },
      { analis: 2, name: "Metamielotsitlar", norma: "-", siBirlik: "" },
      {
        analis: 2,
        name: "Tayoqcha yadroli neytrofil",
        norma: "1-6",
        siBirlik: "%",
      },
      {
        analis: 2,
        name: "Segment yadroli neytrofil",
        norma: "47-72",
        siBirlik: "%",
      },
      { analis: 2, name: "Eozinofillar", norma: "0,5-5", siBirlik: "%" },
      { analis: 2, name: "Bazofillar", norma: "0 - 1", siBirlik: "%" },
      { analis: 2, name: "Monotsitlar", norma: "3-11", siBirlik: "%" },
      { analis: 2, name: "Limfotsitlar", norma: "19-37", siBirlik: "%" },
      { analis: 2, name: "Plazmatik xujayralar", norma: "-", siBirlik: "%" },
      {
        analis: 2,
        name: "Eritrotsitning cho'kish tezligi (ECHT)",
        norma: "E: 2-10  A: 2-15",
        siBirlik: "mm/soat",
      },
      { analis: 2, name: "Gemoglobin A1c", norma: "4,0-5,6", siBirlik: "%" },
      {
        analis: 2,
        name: "C-reaktiv oqsil (CRP)",
        norma: "<5",
        siBirlik: "mg/l",
      },
      { analis: 2, name: "Fibrinogen", norma: "2-4", siBirlik: "g/l" },
      { analis: 2, name: "Prokalsitonin", norma: "<0.5", siBirlik: "ng/ml" },
    ],
  },

  {
    label: "Eritrotsitlar",
    options: [
      {
        analis: 3,
        name: "Anizotsitoz (mikrotsitlar, makrotsitlar, megalotsitlar)",
      },
      {
        analis: 3,
        name: "Poykilotsitoz",
      },
      { analis: 3, name: "Bazofil donador eritrotsitlar" },
      { analis: 3, name: "Polixromatofiliya" },
      { analis: 3, name: "Jolli tanachasi, Kebot xalkasi" },
      { analis: 3, name: "Eritro-normoblastlar (100ta leykotsitga nisbatan)" },
      { analis: 3, name: "Megalotsitlar" },
      { analis: 3, name: "Leykotsitlar morfologiyasi" },
      { analis: 3, name: "Yadro gipersegmentatsiyasi" },
      { analis: 3, name: "Toksogen donadorlik" },
      { analis: 3, name: "Gipohromiya" },
      { analis: 3, name: "Hiperhromiya" },
      { analis: 3, name: "Sferotsitoz" },
      { analis: 3, name: "Ovalotsitoz" },
      { analis: 3, name: "Stomatotsitoz" },
      { analis: 3, name: "Klorotsitoz" },
      { analis: 3, name: "Anisokromiya" },
      { analis: 3, name: "Poikilotsitoz" },
      { analis: 3, name: "Leptotsitoz" },
    ],
  },

  // Siydik taxlili
  {
    label: "Siydik Tahlili",
    options: [
      { analis: 4, name: "Miqdori", gl: "l*", ml: "miqdoriMl", gr: "ml*" },
      { analis: 4, name: "Rangi" },
      { analis: 4, name: "Umumiy siydik tahlili" },
      { analis: 4, name: "Tiniqligi" },
      { analis: 4, name: "Nisbiy zichligi" },
      { analis: 4, name: "Reaktsiya" },
      { analis: 4, name: "Oqsil", gl: "g/l*", ml: "oqsilGl", gr: "g/ %**" },
      {
        analis: 4,
        name: "Glyukoza",
        gl: "mmol'/l* ",
        ml: "GlyukozaMmol",
        gr: "g/ %**",
      },
      { analis: 4, name: "Keton tanachalari" },
      { analis: 4, name: "Qon borligini aniqlash reaktsiyasi" },
      { analis: 4, name: "Bilirubin" },
      { analis: 4, name: "Urobilinoidlar" },
      { analis: 4, name: "O't kiotasi" },
      {
        analis: 4,
        name: "Asidiylik (pH)",
        gl: "pH*",
        ml: "asidiylikPh",
        gr: "pH %**",
      },
      {
        analis: 4,
        name: "Sodiy",
        gl: "mmol/l*",
        ml: "sodiyMmol",
        gr: "mmol/l %**",
      },
      {
        analis: 4,
        name: "Kaltsiy",
        gl: "mmol/l*",
        ml: "kaltsiyMmol",
        gr: "mmol/l %**",
      },
      {
        analis: 4,
        name: "Mochevina",
        gl: "mmol/l*",
        ml: "mochevinaMmol",
        gr: "mmol/l %**",
      },
      {
        analis: 4,
        name: "Kreatinin",
        gl: "mmol/l*",
        ml: "KreatininMmol",
        gr: "mmol/l %**",
      },
      {
        analis: 4,
        name: "Protein (Pro) chaqirilgan analiz",
        gl: "g/l*",
        ml: "proteinGl",
        gr: "g/l %**",
      },
    ],
  },

  {
    label: "Chokmas Tahlili",
    options: [
      { analis: 5, name: "Epiteliy yassi:" },
      { analis: 5, name: "Epiteliy o'tuvchi:" },
      { analis: 5, name: "Epiteliy buyrak:" },
      { analis: 5, name: "Leykotsitlar:" },
      { analis: 5, name: "Eritrotsitlar o'zgargan:" },
      { analis: 5, name: "Eritrotsitlar o'zgarmagan:" },
      { analis: 5, name: "Silindrlar gialinli:" },
      { analis: 5, name: "Silindrlar mumsimon:" },
      { analis: 5, name: "Silindrlar donadore:" },
      { analis: 5, name: "Silindrlar eritrotsitar:" },
      { analis: 5, name: "Silindrlar epitelial:" },
      { analis: 5, name: "Silindrlar leykotsitar:" },
      { analis: 5, name: "Shilliq:" },
      { analis: 5, name: "Tuzlar:" },
      { analis: 5, name: "Bakteriyalar:" },
      { analis: 5, name: "Kristallar:" },
      { analis: 5, name: "Mukoza:" },
      { analis: 5, name: "Kislotalar:" },
      { analis: 5, name: "Qon:" },
      { analis: 5, name: "Kislota kislotasi:" },
      { analis: 5, name: "Pielonifrit belgilari:" },
    ],
  },
];
