import React, { useRef, useEffect, useState } from "react";
import { Button, Tabs, message, Popover } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { useForm, Controller, useWatch } from "react-hook-form";
import LayoutWrapper from "../../../components/layout/Layout";
import { useGetStoryByIdQuery, useUpdateStoryMutation } from "../../../redux/clientStores";
import { useGetDoctorByIdNumberQuery, useUpdateDoctorSalaryMutation } from "../../../redux/doctorApi";
import { useUpdateClientMutation, useGetUsersQuery } from "../../../redux/clientApi";
import PrintCheckList from "./PrintCheckList";  // Import PrintCheckList component
import "./style.css";

const BioKimyoviy = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [updateClient] = useUpdateClientMutation();
  const { data: allClients } = useGetUsersQuery();
  const clients = allClients?.data || [];
  const navigate = useNavigate();
  const mainform = useRef();
  const printRef = useRef();  // Ref for PrintCheckList component
  const id = useParams()._id;
  const { data: singleStory } = useGetStoryByIdQuery(id);
  let story = singleStory?.innerData?.singleStory?.extraTreatment || [];
  const [popoverVisible, setPopoverVisible] = useState(false);
  useEffect(() => {
    const total = story.reduce((acc, cur) => acc + (cur.price || 0), 0);
    setTotalPrice(total);
  }, [story]);

  const analysisGroups = {
    "Qonning biokimyoviy taxlili": story.filter((i) => i.analis === "1"),
    "Qonning umumiy taxlili": story.filter((i) => i.analis === "2"),
    "Eritrotsitlar morfologiyasi": story.filter((i) => i.analis === "3"),
    "Siydik taxlili": story.filter((i) => i.analis === "4"),
    "Chokma taxlili": story.filter((i) => i.analis === "5"),
  };

  const [updateStory] = useUpdateStoryMutation();
  const [updateDoctorSalary] = useUpdateDoctorSalaryMutation();
  const doctorID = localStorage.getItem("doctorID");
  const { data: { data: doctorData } = {} } = useGetDoctorByIdNumberQuery(doctorID || "");
  console.log(doctorData);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      extraTreatment: story.reduce((acc, cur) => {
        acc["result" + cur.key] = cur.result || "";
        return acc;
      }, {}),
    },
  });

  const watchedFormData = useWatch({ control });  // Watch form data in real-time

  const onSubmit = (formData) => {
    let dataStory = { ...singleStory.innerData.singleStory };

    dataStory.extraTreatment = story.map((item) => ({
      ...item,
      result: formData["result" + item.key],
    }));

    dataStory.paySumm = +totalPrice;
    dataStory.view = true;

    updateStory({ id: dataStory._id, body: dataStory })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    updateDoctorSalary({
      salary: dataStory.paySumm,
      doctor_id: dataStory.doctorIdNumber,
    });

    let user = { ...clients.find((u) => u._id === dataStory.clientID) };
    user.totalPrice = +user.totalPrice + +dataStory.paySumm;

    updateClient({ id: user._id, body: user })
      .then((res) => {
        if (res?.data?.success) {
          message.success("Qaradorlikka o'tkazildi!");
        }
      })
      .catch((err) => console.log("err", err));

    navigate("/appointments");
  };



  const handleOk = () => {
    setPopoverVisible(false);
    handleSubmit(onSubmit)(); // Formani yuborish uchun chaqiramiz
  };

  const handleCancel = () => {
    setPopoverVisible(false);
  };
  const popoverContent = (
    <div>
      <p>Natijalarni chop etib berdingizmi?</p>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        <Button type="primary" size="small" onClick={handleOk}>
          HA
        </Button>
        <Button size="small" onClick={handleCancel}>
          YO‘Q
        </Button>
      </div>
    </div>
  );

  const operations = (
    <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
      <Popover
        content={popoverContent}
        trigger="click"
        open={popoverVisible}
        onOpenChange={setPopoverVisible}
      >
        <Button
          shape="round"
          icon={<CheckOutlined />}
          className="BtnPrimary"
          type="primary"
        />
      </Popover>
      <ReactToPrint
        trigger={() => (
          <Button
            shape="round"
            icon={<PrinterOutlined />}
            className="BtnPrimary"
            type="default"
          >
            Print
          </Button>
        )}
        content={() => printRef.current}
      />
    </div>
  );

  return (
    <LayoutWrapper>
      <div>
        <Tabs className="BioKimyoviy" tabBarExtraContent={operations}>
          {Object.entries(analysisGroups).map(([tabTitle, data], index) =>
            data.length ? (
              <Tabs.TabPane tab={tabTitle} key={index}>
                <form ref={mainform} id="mainForm" onSubmit={handleSubmit(onSubmit)}>
                  <table className="tableAnalis">
                    <thead>
                      <tr>
                        <th>Ko'rsatkich</th>
                        <th>Natija</th>
                        <th>Norma</th>
                        <th>SI birlik</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, inx) => (
                        <tr key={inx}>
                          <td>{item.name}</td>
                          <td>
                            <Controller
                              name={"result" + item.key}
                              control={control}
                              render={({ field }) => (
                                <input {...field} placeholder="Yozing..." className="resInp" type="text" />
                              )}
                            />
                          </td>
                          <td>{item.norma}</td>
                          <td>{item.siBirlik}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </form>
              </Tabs.TabPane>
            ) : null
          )}
        </Tabs>
        <div style={{ display: "none" }}>
          <PrintCheckList
            ref={printRef}  // PrintCheckList componentiga ref uzatish
            analysisGroups={analysisGroups}  // Analysis groups ni uzatish
            formData={watchedFormData}  // Real-time form data ni uzatish
            doctorData={doctorData}
            singleStory={singleStory}
          />
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BioKimyoviy;












// import React, { useRef, useEffect, useState } from "react";
// import { Button, Tabs, message } from "antd";
// import { useNavigate, useParams } from "react-router-dom";
// import { CheckOutlined, PrinterOutlined } from "@ant-design/icons";
// import ReactToPrint from "react-to-print";
// import { useForm, Controller } from "react-hook-form";
// import LayoutWrapper from "../../../components/layout/Layout";
// import { useGetStoryByIdQuery, useUpdateStoryMutation } from "../../../redux/clientStores";
// import { useGetDoctorByIdNumberQuery, useUpdateDoctorSalaryMutation } from "../../../redux/doctorApi";
// import { useUpdateClientMutation, useGetUsersQuery } from "../../../redux/clientApi";
// import PrintCheckList from "./PrintCheckList";  // Import PrintCheckList component
// import "./style.css";

// const BioKimyoviy = () => {
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [updateClient] = useUpdateClientMutation();
//   const { data: allClients } = useGetUsersQuery();
//   const clients = allClients?.data || [];
//   const navigate = useNavigate();
//   const mainform = useRef();
//   const printRef = useRef();  // Ref for PrintCheckList component
//   const id = useParams()._id;
//   const { data: singleStory } = useGetStoryByIdQuery(id);
//   let story = singleStory?.innerData?.singleStory?.extraTreatment || [];

//   useEffect(() => {
//     const total = story.reduce((acc, cur) => acc + (cur.price || 0), 0);
//     setTotalPrice(total);
//   }, [story]);

//   const analysisGroups = {
//     "Qonning biokimyoviy taxlili": story.filter((i) => i.analis === "1"),
//     "Qonning umumiy taxlili": story.filter((i) => i.analis === "2"),
//     "Eritrotsitlar morfologiyasi": story.filter((i) => i.analis === "3"),
//     "Siydik taxlili": story.filter((i) => i.analis === "4"),
//     "Chokma taxlili": story.filter((i) => i.analis === "5"),
//   };

//   const [updateStory] = useUpdateStoryMutation();
//   const [updateDoctorSalary] = useUpdateDoctorSalaryMutation();
//   const doctorID = localStorage.getItem("doctorID");
//   const { data: { data: doctorData } = {} } = useGetDoctorByIdNumberQuery(doctorID || "");

//   console.log(doctorData);
//   const { control, handleSubmit, getValues } = useForm({
//     defaultValues: {
//       extraTreatment: story.reduce((acc, cur) => {
//         acc["result" + cur.key] = cur.result || "";
//         return acc;
//       }, {}),
//     },
//   });

//   const onSubmit = (formData) => {
//     let dataStory = { ...singleStory.innerData.singleStory };

//     dataStory.extraTreatment = story.map((item) => ({
//       ...item,
//       result: formData["result" + item.key],
//     }));

//     dataStory.paySumm = +totalPrice;
//     dataStory.view = true;

//     updateStory({ id: dataStory._id, body: dataStory })
//       .then((res) => console.log(res))
//       .catch((err) => console.log(err));

//     updateDoctorSalary({
//       salary: dataStory.paySumm,
//       doctor_id: dataStory.doctorIdNumber,
//     });

//     let user = { ...clients.find((u) => u._id === dataStory.clientID) };
//     user.totalPrice = +user.totalPrice + +dataStory.paySumm;

//     updateClient({ id: user._id, body: user })
//       .then((res) => {
//         if (res?.data?.success) {
//           message.success("Qaradorlikka o'tkazildi!");
//         }
//       })
//       .catch((err) => console.log("err", err));

//     navigate("/appointments");
//   };

//   const operations = (
//     <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
//       <Button
//         onClick={handleSubmit(onSubmit)}
//         shape="round"
//         icon={<CheckOutlined />}
//         className="BtnPrimary"
//         type="primary"
//       />
//       <ReactToPrint
//         trigger={() => (
//           <Button
//             shape="round"
//             icon={<PrinterOutlined />}
//             className="BtnPrimary"
//             type="default"
//           >
//             Print
//           </Button>
//         )}
//         content={() => printRef.current}
//       />
//     </div>
//   );

//   return (
//     <LayoutWrapper>
//       <div>
//         <Tabs className="BioKimyoviy" tabBarExtraContent={operations}>
//           {Object.entries(analysisGroups).map(([tabTitle, data], index) =>
//             data.length ? (
//               <Tabs.TabPane tab={tabTitle} key={index}>
//                 <form ref={mainform} id="mainForm" onSubmit={handleSubmit(onSubmit)}>
//                   <table className="tableAnalis">
//                     <thead>
//                       <tr>
//                         <th>Ko'rsatkich</th>
//                         <th>Natija</th>
//                         <th>Norma</th>
//                         <th>SI birlik</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {data.map((item, inx) => (
//                         <tr key={inx}>
//                           <td>{item.name}</td>
//                           <td>
//                             <Controller
//                               name={"result" + item.key}
//                               control={control}
//                               render={({ field }) => (
//                                 <input {...field} placeholder="Yozing..." className="resInp" type="text" />
//                               )}
//                             />
//                           </td>
//                           <td>{item.norma}</td>
//                           <td>{item.siBirlik}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </form>
//               </Tabs.TabPane>
//             ) : null
//           )}
//         </Tabs>
//         <PrintCheckList
//           ref={printRef}  // Pass the ref to PrintCheckList
//           analysisGroups={analysisGroups}  // Pass analysis groups
//           formData={getValues()}  // Pass form data
//         />
//       </div>
//     </LayoutWrapper>
//   );
// };

// export default BioKimyoviy;













// import React, { useRef, useEffect, useState } from "react";
// import { Button, Tabs, message } from "antd";
// import { useNavigate, useParams } from "react-router-dom";
// import { CheckOutlined } from "@ant-design/icons";
// import "./style.css";
// import { useForm, Controller } from "react-hook-form";
// import LayoutWrapper from "../../../components/layout/Layout";
// import { useGetStoryByIdQuery, useUpdateStoryMutation } from "../../../redux/clientStores";
// import { useGetDoctorByIdNumberQuery, useUpdateDoctorSalaryMutation } from "../../../redux/doctorApi";
// import { useUpdateClientMutation, useGetUsersQuery } from "../../../redux/clientApi";

// const BioKimyoviy = () => {
//   const [totalPrice, setTotalPrice] = useState(0);
//   let [updateClient] = useUpdateClientMutation();
//   let { data: allClients } = useGetUsersQuery();
//   let clients = allClients?.data || [];
//   const navigate = useNavigate();
//   const mainform = useRef();
//   const id = useParams()._id;
//   const { data: singleStory } = useGetStoryByIdQuery(id);
//   let story = singleStory?.innerData?.singleStory?.extraTreatment;

//   useEffect(() => {
//     // Barcha price qiymatlarini yig'indisini hisoblash
//     const total = story.reduce((acc, cur) => {
//       return acc + (cur.price || 0); // Agar price mavjud bo'lmasa 0 qiymatni qo'shing
//     }, 0);

//     setTotalPrice(total); // Hisoblangan qiymatni state ga saqlash
//   }, [story]); // story o'zgarganda qayta hisoblash

//   if (!Array.isArray(story)) {
//     console.error("Story is not an array:", story);
//     story = []; // Fallback to an empty array
//   }

//   const BioData = story.filter((i) => i.analis === "1");
//   const UmumiyTaxlilData = story.filter((i) => i.analis === "2");
//   const Eritrotsitlar = story.filter((i) => i.analis === "3");
//   const SiydikTahlilData = story.filter((i) => i.analis === "4");
//   const ChokmasData = story.filter((i) => i.analis === "5");

//   const [updateStory] = useUpdateStoryMutation();
//   const [updateDoctorSalary] = useUpdateDoctorSalaryMutation();
//   let doctorID = localStorage.getItem("doctorID");
//   const { data: { data } = { data: {} } } = useGetDoctorByIdNumberQuery(
//     doctorID || ""
//   );

//   console.log(data);


//   const { control, handleSubmit } = useForm({
//     defaultValues: {
//       extraTreatment: story.reduce((acc, cur) => {
//         acc["result" + cur.key] = cur.result || "";
//         return acc;
//       }, {}),
//     },
//   });

//   const onSubmit = (formData) => {
//     let dataStory = { ...singleStory.innerData.singleStory };

//     // Update extraTreatment results
//     dataStory.extraTreatment = story.map((item) => {
//       return {
//         ...item,
//         result: formData["result" + item.key],
//       };
//     });

//     dataStory.paySumm = +totalPrice;
//     dataStory.view = true;
//     console.log(dataStory);


//     updateStory({ id: dataStory?._id, body: dataStory })
//       .then((res) => console.log(res))
//       .catch((err) => console.log(err));

//     updateDoctorSalary({
//       salary: dataStory.paySumm,
//       doctor_id: dataStory.doctorIdNumber,
//     });

//     let user = { ...clients?.find((u) => u._id === dataStory.clientID) };
//     user.totalPrice = +user.totalPrice + +dataStory.paySumm;

//     updateClient({ id: user._id, body: user })
//       .then((res) => {
//         if (res?.data?.success) {
//           message.success("Qaradorlikka o'tkazildi!");
//         }
//       })
//       .catch((err) => console.log("err", err));
//     navigate("/appointments");
//   };

//   const operations = (
//     <Button
//       onClick={handleSubmit(onSubmit)}  // Formani yuborish va sayt yangilanishini oldini olish
//       shape="round"
//       icon={<CheckOutlined />}
//       className="BtnPrimary"
//       type="primary"
//     />
//   );

//   return (
//     <LayoutWrapper>
//       <Tabs className="BioKimyoviy" tabBarExtraContent={operations}>
//         {BioData?.length ?
//           <Tabs.TabPane
//             defaultActiveKey="0"
//             tab="Qonning biokimyoviy taxlili"
//             key={0}
//           >
//             <form ref={mainform} id="mainForm" onSubmit={handleSubmit(onSubmit)}>
//               <table className="tableAnalis">
//                 <thead>
//                   <tr>
//                     <th>Tekshiriluvchi ko'rsatkich</th>
//                     <th>Natija</th>
//                     <th>Norma</th>
//                     <th>SI birlik</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {BioData?.map((item, inx) => (
//                     <tr key={inx}>
//                       <td data-label={item.name}>{item.name}</td>
//                       <td>
//                         <Controller
//                           name={"result" + item.key}
//                           control={control}
//                           render={({ field }) => (
//                             <input
//                               {...field}
//                               placeholder="Yozing..."
//                               className="resInp"
//                               type="text"
//                             />
//                           )}
//                         />
//                       </td>
//                       <td data-label={item.norma}>{item.norma}</td>
//                       <td data-label={item.siBirlik}>{item.siBirlik}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </form>
//           </Tabs.TabPane>
//           : ""
//         }

//         {UmumiyTaxlilData?.length ?
//           <Tabs.TabPane defaultActiveKey="1" tab="Qonning umumiy taxlili" key={1}>
//             <form ref={mainform} onSubmit={handleSubmit(onSubmit)}>
//               <div className="umumiyQon">
//                 <table className="tableAnalis">
//                   <thead>
//                     <tr>
//                       <th>Ko'rsatkich</th>
//                       <th>Natija</th>
//                       <th colSpan={2}>Norma(SI birligi)</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {UmumiyTaxlilData?.map((item, inx) => (
//                       <tr key={inx}>
//                         <td data-label={item.name}>{item.name}</td>
//                         <td>
//                           <Controller
//                             name={"result" + item.key}
//                             control={control}
//                             render={({ field }) => (
//                               <input
//                                 {...field}
//                                 placeholder="Yozing..."
//                                 className="resInp"
//                                 type="text"
//                               />
//                             )}
//                           />
//                         </td>
//                         <td data-label={item.norma}>{item.norma}</td>
//                         <td data-label={item.siBirlik}>{item.siBirlik}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </form>
//           </Tabs.TabPane>
//           : ""
//         }

//         {Eritrotsitlar?.length ?
//           <Tabs.TabPane
//             defaultActiveKey="2"
//             tab="Eritrotsitlar morfologiyasi"
//             key={2}
//           >
//             <form ref={mainform} onSubmit={handleSubmit(onSubmit)}>
//               <div className="umumiyQon">
//                 <table className="tableAnalis">
//                   <thead>
//                     <tr>
//                       <th>Eritrotsitlar morfologiyasi</th>
//                       <th>Eritrotsitlar natijasi</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {Eritrotsitlar?.map((item, inx) => (
//                       <tr key={inx}>
//                         <td data-label={item.name}>
//                           <>{item.name}</>
//                         </td>
//                         <td>
//                           <Controller
//                             name={"result" + item.key}
//                             control={control}
//                             render={({ field }) => (
//                               <input
//                                 {...field}
//                                 placeholder="Yozing..."
//                                 className="resInp"
//                                 type="text"
//                               />
//                             )}
//                           />
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </form>
//           </Tabs.TabPane> : ""
//         }

//         {SiydikTahlilData?.length ?
//           <Tabs.TabPane defaultActiveKey="3" tab="Siydik taxlili" key={3}>
//             <form ref={mainform} onSubmit={handleSubmit(onSubmit)}>
//               <div className="umumiyQon">
//                 <table className="tableAnalis">
//                   <thead>
//                     <tr>
//                       <th>Fizik - kimyoviy xossasi</th>
//                       <th>Kimyoviy xossasi natijasi</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {SiydikTahlilData?.map((item, inx) => (
//                       <tr key={inx}>
//                         <td data-label={item.name}>
//                           <>{item.name}</>
//                         </td>
//                         <td>
//                           <Controller
//                             name={"result" + item.key}
//                             control={control}
//                             render={({ field }) => (
//                               <input
//                                 {...field}
//                                 placeholder="Yozing..."
//                                 className="resInp"
//                                 type="text"
//                               />
//                             )}
//                           />
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </form>
//           </Tabs.TabPane> : ""
//         }
//         {ChokmasData?.length ?
//           <Tabs.TabPane defaultActiveKey="4" tab="Chokma taxlili" key={4}>
//             <form ref={mainform} onSubmit={handleSubmit(onSubmit)}>
//               <div className="umumiyQon">
//                 <table className="tableAnalis">
//                   <thead>
//                     <tr>
//                       <th>Chokma taxlili ko'rsatkichlari</th>
//                       <th>Chokma taxlili natijalari</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {ChokmasData?.map((item, inx) => (
//                       <tr key={inx}>
//                         <td data-label={item.name}>
//                           <>{item.name}</>
//                         </td>
//                         <td>
//                           <Controller
//                             name={"result" + item.key}
//                             control={control}
//                             render={({ field }) => (
//                               <input
//                                 {...field}
//                                 placeholder="Yozing..."
//                                 className="resInp"
//                                 type="text"
//                               />
//                             )}
//                           />
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </form>
//           </Tabs.TabPane>
//           : ""
//         }
//       </Tabs>
//     </LayoutWrapper>
//   );
// };

// export default BioKimyoviy;
