import React from "react";
import "./style.css";
import { GiMoneyStack } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { Button, Popover } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { NumberFormat } from "../../../hook/NumberFormat";
import LayoutWrapper from "../../../components/layout/Layout";
import img1 from "../../../assets/img/singleImg1.png";
import img2 from "../../../assets/img/singleImg2.png";
import LogoMedme from "../../../assets/img/logo.png";
import { useGetReportsQuery } from "../../../redux/reports";
import ReportsLoading from "../../admin/singlePage/singleReports/ReportsLoading";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";

function Wallet() {
  let time = new Date();
  let _id = localStorage.getItem("doctorID");
  const { data: allDoctors } = useGetAllDoctorsQuery();
  const doctor = allDoctors?.data?.find((i) => i.idNumber === _id);
  let { data: allReports, isLoading } = useGetReportsQuery([_id]);
  let report = allReports?.innerData[0] || [];

  if (isLoading) {
    return (
      <LayoutWrapper>
        <ReportsLoading />
      </LayoutWrapper>
    ); // Display a loading indicator while data is being fetched
  }

  const Style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const formatNumber = (number) => number.toLocaleString("en-US") + " so'm";

  const popoverContent = (record) => {
    if (!record || !record?.fizioterapiyaPrices) {
      return null;
    }

    return (
      <div className="Popover">
        <div>
          <span>Yangi bemor uchun!</span>
          {Object.keys(record?.fizioterapiyaPrices).map((therapy, index) => (
            <div key={index}>{`${therapy}: ${formatNumber(
              record.fizioterapiyaPrices[therapy].newClient
            )}`}</div>
          ))}
        </div>
        <div>
          <span>Davolanyotgan bemor uchun!</span>
          {Object.keys(record.fizioterapiyaPrices).map((therapy, index) => (
            <div key={index}>{`${therapy}: ${formatNumber(
              record.fizioterapiyaPrices[therapy].treatingPatent
            )}`}</div>
          ))}
        </div>
      </div>
    );
  };

  const popoverContentAnalise = (record) => {
    if (!record || !record?.analisisPrices) {
      return null;
    }

    return (
      <div>
        {Object.keys(record?.analisisPrices).map((therapy, index) => (
          <div key={index}>{`${therapy}: ${formatNumber(
            record.analisisPrices[therapy]
          )}`}</div>
        ))}
      </div>
    );
  };

  return (
    <LayoutWrapper>
      <div className="containerWallet">
        <div className="Wallet">
          <div className="headerWallet">
            <div className="header-summary">
              <div className="summary-text-owner">Balans</div>
              <div className="summary-balance">
                {NumberFormat(doctor?.salary)} so'm
              </div>
              {doctor?.regularSalary > 0 && (
                <>
                  <div className="summary-text-owner-salary">
                    Oylik ish maoshi
                  </div>
                  <div className="summary-text-owner-salary">
                    {NumberFormat(doctor?.regularSalary)} so'm
                  </div>
                </>
              )}
            </div>

            <div className="imgBoxProfile">
              <div className="user-profile">
                <img
                  src={doctor?.lastName?.endsWith("a") ? img2 : img1}
                  alt=""
                />
              </div>
              <div>
                {doctor?.firstName?.toUpperCase() +
                  "." +
                  doctor?.lastName[0]?.toUpperCase()}
              </div>
              <p>{doctor?.specialization}</p>
            </div>
          </div>
          <div className="contentWallet">
            <div className="cardWallet">
              <div className="upper-row">
                <div className="card-item">
                  <span>Bugungi balans</span>
                  <span>{NumberFormat(report?.ownPrice)} so'm</span>
                </div>
                <div className="card-item">
                  <span>Oylik foizda</span>
                  <span>{doctor?.percent}%</span>
                </div>
              </div>
              <div className="lower-row">
                <div className="icon-item">
                  <div className="icon">
                    {NumberFormat(report?.clientLength)}
                  </div>
                  <div className="icon-text">
                    <FaUsers /> Bugun
                  </div>
                </div>
                <div className="icon-item">
                  <div className="icon">
                    {NumberFormat(report?.monthClientLength)}
                  </div>
                  <div className="icon-text">
                    <FaUsers />
                    {time.toLocaleString("en-Us", { month: "long" })}
                  </div>
                </div>
                <div className="icon-item">
                  <div className="icon">
                    {NumberFormat(report?.monthlyTotalPrice)} so'm
                  </div>
                  <div className="icon-text">
                    <GiMoneyStack /> Bir oylik tushim
                  </div>
                </div>
                <div className="icon-item">
                  <div className="icon">
                    {doctor?.specialization === "Fizioterapiya" ||
                    doctor?.specialization === "Labarant" ? (
                      <>
                        {doctor.specialization === "Fizioterapiya" ? (
                          <Popover content={popoverContent(doctor)}>
                            <Button style={Style}>
                              <EyeOutlined />
                            </Button>
                          </Popover>
                        ) : (
                          <Popover content={popoverContentAnalise(doctor)}>
                            <Button style={Style}>
                              <EyeOutlined />
                            </Button>
                          </Popover>
                        )}
                      </>
                    ) : (
                      <>{NumberFormat(doctor?.feesPerCunsaltation)} so'm</>
                    )}
                  </div>
                  <div className="icon-text">
                    <GiMoneyStack /> Qabul
                  </div>
                </div>
                {doctor?.secondPrice === 0 ? (
                  ""
                ) : (
                  <div className="icon-item">
                    <div className="icon">
                      {NumberFormat(doctor?.secondPrice)} so'm
                    </div>
                    <div className="icon-text">
                      <GiMoneyStack /> Ikkilamchi
                    </div>
                  </div>
                )}
                {doctor?.specialization === "Fizioterapiya" ||
                doctor?.specialization === "Labarant" ? (
                  ""
                ) : (
                  <div div className="icon-item">
                    <div className="icon">
                      {NumberFormat(report.roomPrice)} so'm
                    </div>
                    <div className="icon-text">
                      <GiMoneyStack /> Davolanish
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="drawer">
            <img width={200} src={LogoMedme} alt="" />
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
}

export default Wallet;
