import React, { useRef } from 'react';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { FaPrint } from 'react-icons/fa'; // Print icon
import { useGetStoriesQuery } from '../../redux/clientStores';
import { capitalizeFirstLetter } from '../../hook/CapitalizeFirstLitter';
import { NumberFormat } from '../../hook/NumberFormat';
import './style.css';

const VidomostBlankAdmins = ({ data, doctors, nurses, salary }) => {
    const clinicID = localStorage.getItem("doctorMongoId");
    const clinicName = localStorage.getItem("clinicName");
    const componentRef = useRef();

    const { data: stories } = useGetStoriesQuery();

    const patsents = stories?.innerData?.filter(patsent => patsent.clinicId === clinicID && patsent.month === new Date().toLocaleString('default', { month: 'long' })) || [];

    function calculateTotalHours(idNumber) {
        const hoursPerId = {};
        const dates = data?.filter(sal => sal.idNumber === idNumber && sal.getMonth === new Date().toLocaleString('default', { month: 'long' }));

        dates?.forEach(item => {
            const id = item.idNumber;
            const { startTime, endTime } = item.workTime;

            // Vaqtlarni hisoblash
            const [startHour, startMinute, startSecond] = startTime.split(':').map(Number);
            const [endHour, endMinute, endSecond] = endTime.split(':').map(Number);

            // Ishlangan vaqtni hisoblash (soatda)
            const workedHours = (endHour - startHour) + (endMinute - startMinute) / 60 + (endSecond - startSecond) / 3600;

            if (!hoursPerId[id]) {
                hoursPerId[id] = 0;
            }
            hoursPerId[id] += workedHours;
        });

        return hoursPerId;
    }


    function calculateTotalSalary(idNumber) {
        const salaryPerId = {};
        const dates = data?.filter(sal => sal.idNumber === idNumber && sal.getMonth === new Date().toLocaleString('default', { month: 'long' }));

        dates?.reduce((accumulator, item) => {
            const id = item?.idNumber;
            const salary = item?.salary;

            if (!accumulator[id]) {
                accumulator[id] = null;
            }
            accumulator[id] += salary;

            return accumulator;
        }, salaryPerId);

        return salaryPerId;
    }



    const allMedicalStaff = [...doctors, ...nurses];

    const currentDate = moment().format('DD.MM.YYYY');
    const months = ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul', 'Avgust', 'Sentabr', 'Oktabr', 'Noyabr', 'Dekabr'];
    const currentMonth = months[new Date().getMonth()];

    // Ushlab qolinadigan summani hisoblash
    const getUshlabQolingan = (idNumber) => {
        const relevantSalaries = salary?.filter(sal => sal.idNumber === idNumber && sal.getMonth === new Date().toLocaleString('default', { month: 'long' }));
        if (relevantSalaries?.length > 0) {
            const avansTaken = relevantSalaries?.find(sal => sal.type === "avans");
            return avansTaken ? "Oyning yarmi uchun avans olingan" : "";
        }
        return "";
    };

    // Maoshni hisoblash (oy davomida barcha avanslar summasi)
    const getSalary = (idNumber) => {
        const relevantSalaries = salary?.filter(sal => sal.idNumber === idNumber
            && sal?.getMonth === new Date().toLocaleString('default', { month: 'long' })
            && sal?.type === "avans"
        );
        return relevantSalaries.reduce((total, sal) => total + sal.salary, 0);
    };

    return (
        <div className="VidomostBlank_Container">
            <ReactToPrint
                trigger={() => (
                    <button className="print-button">
                        <FaPrint />
                    </button>
                )}
                content={() => componentRef.current}
            />
            <div className="pay-slip" ref={componentRef}>
                <div className="sub-header">
                    <table>
                        <tbody>
                            <tr>
                                <td>To'ldirish sanasi</td>
                                <td>Korxona (muassasa, tashkilot) nomi</td>
                                <td>Bemorlar soni</td>
                            </tr>
                            <tr>
                                <td>{currentDate}</td>
                                <td>{clinicName}</td>
                                <td>{patsents.length}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content_Vidomost">
                    <h2>Xisob-kitob oylik to'lov varaqasi: {currentMonth} 2024 yil uchun</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Т/Р</th>
                                <th>Ism Familiya / Kasbi</th>
                                <th>Ish soati</th>
                                <th>Ушлаб қолинган</th>
                                <th>ушлаб қолинган, сўм</th>
                                <th>Oylik Maosh, so'm</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allMedicalStaff.map((person, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{capitalizeFirstLetter(person?.firstName)} {person.lastName} ({person.specialization || 'Hamshira'})</td>
                                    <td>{calculateTotalHours(person.idNumber)[person.idNumber]?.toFixed(2) || ""} </td>
                                    <td>{getUshlabQolingan(person?.idNumber)}</td>
                                    <td>{NumberFormat(getSalary(person?.idNumber))}</td>
                                    <td>
                                        {NumberFormat(person?.salary)} {/* Assuming NumberFormat returns a string or number */}
                                        {' '}
                                        {calculateTotalSalary(person?.idNumber)[person?.idNumber]} {/* Assuming you want to display the salary for a specific ID */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default VidomostBlankAdmins;




