import React, { useRef, useState } from "react";
import "./DailyReport.css";
import moment from "moment";
import { Button, DatePicker, Tabs } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { SiMicrosoftexcel } from "react-icons/si";
import * as XLSX from "xlsx";
import Layout from "../../../components/layout/Layout";
import {
  useGetDailyReportQuery,
  useGetDailyCostQuery,
} from "../../../redux/dailyReportApi";

const { RangePicker } = DatePicker;

function DailyReport() {
  const printRefKunlik = useRef();
  const printRefChiqimlar = useRef();

  // USE STATES
  const today = moment().format("DD.MM.YYYY");
  const [selectedDate] = useState(moment());
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [startDateLive, setStartDateLive] = useState(today); // chiqim uchun
  const [endDateLive, setEndDateLive] = useState(today); // chiqim uchun

  // RTK QUERIES
  const { data: data, refetch } = useGetDailyReportQuery({
    startDate,
    endDate,
  });

  const { data: dailyCostData, refetch: refetchCost } = useGetDailyCostQuery({
    startDate: startDateLive,
    endDate: endDateLive,
  });

  const handleDateRangeChangeLive = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      const start = startDate.format("DD.MM.YYYY");
      const end = endDate.format("DD.MM.YYYY");

      setStartDateLive(start);
      setEndDateLive(end);
      refetchCost();
    } else {
      setStartDateLive(null);
      setEndDateLive(null);
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      const start = startDate.format("DD.MM.YYYY");
      const end = endDate.format("DD.MM.YYYY");

      // Tanlangan sanalarni state ga saqlash
      setStartDate(start);
      setEndDate(end);
      refetch();
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const exportToExcelDoctors = () => {
    const dataForExcel = data?.doctorReport?.map((item) => ({
      Doktor: item.doctor,
      Bemorlar: item.length,
      "Тўлов (нақт)": item.totalSum,
      "Тўлов (карта)": item.plasticCardSum,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Kunlik Hisobot");

    const totalRow = {
      Doktor: "Jami",
      Bemorlar: data?.overallReport.totalPatients?.toLocaleString(),
      "Тўлов (нақт)": data.overallReport.totalCash,
      "Тўлов (карта)": data.overallReport.totalPlasticCard,
    };
    XLSX.utils.sheet_add_json(worksheet, [totalRow], {
      skipHeader: true,
      origin: -1,
    });

    XLSX.writeFile(workbook, `Kunlik_Hisobot_${today}.xlsx`);
  };

  const exportToExcelChiqimlar = () => {
    const dataForExcelChiqimlar = dailyCostData?.dailyCost?.map(
      (item, index) => ({
        "№": index + 1,
        "Kim Tomonidan": item.name,
        Kategoriya: item.category,
        "Summa (so'm)": item.amount?.toLocaleString(),
      })
    );

    const worksheet = XLSX.utils.json_to_sheet(dataForExcelChiqimlar);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Chiqimlar");

    const totalRow = {
      "№": "",
      "KIm Tomonidan": "Jami",
      Kategoriya: "",
      "Summa (so'm)": dailyCostData?.totalAmount?.toLocaleString(),
    };
    XLSX.utils.sheet_add_json(worksheet, [totalRow], {
      skipHeader: true,
      origin: -1,
    });

    XLSX.writeFile(workbook, `Chiqimlar_Hisoboti_${today}.xlsx`);
  };

  return (
    <Layout>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Kunlik Hisobot" key="1">
          <div
            className="ReactToPrint"
            style={{ display: "flex", gap: "10px", marginBottom: "15px" }}
          >
            <RangePicker
              onChange={handleDateRangeChange}
              format="DD.MM.YYYY"
              style={{ marginBottom: 5 }}
              placeholder={["Бошланиш санаси", "Тугаш санаси"]}
            />

            <ReactToPrint
              trigger={() => (
                <Button type="primary">
                  <PrinterOutlined style={{ fontSize: "20px" }} />
                </Button>
              )}
              content={() => printRefKunlik.current}
            />
            <Button type="primary" onClick={exportToExcelDoctors}>
              <SiMicrosoftexcel />
              Эксл
            </Button>
          </div>
          <div ref={printRefKunlik} className="dailyReport">
            <b className="dailyReport_title">
              {startDate === today && endDate === today
                ? `Кунлик ҳисобот - ${selectedDate.format("DD.MM.YYYY")}`
                : `${startDate} дан ${endDate} гача бўлган ҳисобот`}
            </b>
            <p className="dailyReport_text">
              Ушбу ҳисоботда ҳар бир бўлимнинг{" "}
              {startDate === today && endDate === today ? "кунлик" : ""} қабул
              қилган беморлари ва тушган
              <br />
              умумий пуллар ҳақида маълумот берилади.
            </p>
            <table className="report_table">
              <thead>
                <tr>
                  <th>Доктор</th>
                  <th>Беморлар</th>
                  <th>Тўлов (нақт)</th>
                  <th>Тўлов (карта)</th>
                </tr>
              </thead>
              <tbody>
                {data?.doctorReport?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "left", paddingLeft: "5px" }}>
                      {item.doctor}
                    </td>
                    <td>{item.length}</td>
                    <td>{item.totalSum}</td>
                    <td>{item.plasticCardSum}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>Жами</td>
                  <td>
                    {data?.overallReport?.totalPatients?.toLocaleString()}
                  </td>
                  <td>{data?.overallReport?.totalCash}</td>
                  <td>{data?.overallReport?.totalPlasticCard}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Chiqimlar" key="2">
          <div
            className="ReactToPrint"
            style={{ display: "flex", gap: "10px", marginBottom: "15px" }}
          >
            <RangePicker
              onChange={handleDateRangeChangeLive}
              format="DD.MM.YYYY"
              style={{ marginBottom: 5 }}
              placeholder={["Бошланиш санаси", "Тугаш санаси"]}
            />

            <ReactToPrint
              trigger={() => (
                <Button type="primary">
                  <PrinterOutlined style={{ fontSize: "20px" }} />
                </Button>
              )}
              content={() => printRefChiqimlar.current}
            />
            <Button type="primary" onClick={exportToExcelChiqimlar}>
              <SiMicrosoftexcel />
              Эксл
            </Button>
          </div>
          <div ref={printRefChiqimlar} className="dailyReport">
            <b className="dailyReport_title">
              {startDateLive === today && endDateLive === today
                ? `Бугунги чиқимлар - ${selectedDate.format("DD.MM.YYYY")}`
                : `${startDateLive} дан ${endDateLive} гача бўлган чиқим ҳисобот`}
            </b>

            <p className="dailyReport_text">
              Қуйидаги жадвалда{" "}
              {startDateLive === today && endDateLive === today
                ? "бугунги кун  давомида"
                : ""}{" "}
              амалга оширилган барча чиқимлар кўрсатилган.
            </p>
            <table className="report_table">
              <thead>
                <tr>
                  <th>№</th>
                  {startDateLive === today && endDateLive === today ? (
                    <></>
                  ) : (
                    <th>Сана</th>
                  )}
                  <th>Ким Томонидан</th>
                  <th>Категория</th>
                  <th>Сумма (сўм)</th>
                </tr>
              </thead>
              <tbody>
                {dailyCostData?.dailyCost?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {startDateLive === today && endDateLive === today ? (
                      <></>
                    ) : (
                      <td>{item.date.day}</td>
                    )}
                    <td>{item.name}</td>
                    <td>{item.category}</td>
                    <td>{item.amount?.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td>Жами</td>
                  <td></td>
                  {startDateLive === today && endDateLive === today ? (
                    <></>
                  ) : (
                    <td></td>
                  )}
                  <td>{dailyCostData?.totalAmount}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Layout>
  );
}

export default DailyReport;
