import React, { useState } from "react";
import {
  SearchOutlined,
  CalendarOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { FaUsers } from "react-icons/fa";
import { Button, Table, DatePicker } from "antd";
import { Link } from "react-router-dom";
import moment from "moment"; // moment.js kutubxonasini import qilamiz
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import { useGetStoriesQuery } from "../../../redux/clientStores";
import LayoutWrapper from "../../../components/layout/Layout";
import { PhoneNumberFormat } from "../../../hook/NumberFormat";
import "dayjs/locale/zh-cn";
import "./style.css";

const GetPatients = () => {
  const [query, setQuery] = useState("");
  const [filteredValue, setFilteredValue] = useState(null);
  const { data: doctor } = useGetAllDoctorsQuery();
  const { data: stories } = useGetStoriesQuery();
  const doctorIdNumber = localStorage.getItem("doctorID");

  const doctors = doctor?.data || [];
  const dataDoctors = doctors.filter((i) => i?.idNumber === doctorIdNumber);

  const storiesData = stories?.innerData || [];
  const clients = storiesData.filter(
    (i) => i?.doctorIdNumber === doctorIdNumber && i?.view
  );

  const columns = [
    {
      title: "Sana",
      dataIndex: "day",
      filterDropdown: ({ dataIndex, confirm }) => (
        <DatePicker
          style={{ width: 200 }}
          format="DD.MM.YYYY"
          value={filteredValue ? moment(filteredValue, "DD.MM.YYYY") : null}
          onChange={(date) => {
            confirm();
            const formattedDate = date.format("D.M.YYYY");
            setFilteredValue(formattedDate);
          }}
        />
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.firstname?.toLowerCase().includes(value?.toLowerCase()),
      render: (text, record) => record.day,
    },
    {
      title: "Bemor",
      dataIndex: "clientFullname",
    },
    {
      title: "Telefon",
      dataIndex: "clientPhone",
      render: (text) => PhoneNumberFormat(text),
    },
    {
      ...(dataDoctors.type === "analis" &&
      dataDoctors.type === "fizioterapiya" ? (
        {
          title: "Status",
          dataIndex: "patientStatus",
        }
      ) : (
        <></>
      )),
    },
    {
      title: "Tarix",
      dataIndex: "tarix",
      render: (text, record) => (
        <Link to={`/poteintsSlinglePage/${record?.id._id}`}>
          <Button className="EyeOutlined" icon={<EyeOutlined />} />
        </Link>
      ),
    },
  ];

  const data =
    clients
      ?.filter((asd) => asd.clientFullname?.toLowerCase().includes(query))
      ?.map((item, i) => ({
        key: i + 1,
        day: item.day,
        clientFullname: item.clientFullname,
        clientPhone: item.clientPhone,
        patientStatus: item.patientStatus,
        id: item,
      })) || [];

  const filterDay = data?.filter((asd) => {
    // Formatni "DD.MM.YYYY" formatga tekshiramiz
    const formattedDate1 = moment(asd?.day, "DD.MM.YYYY");
    if (
      formattedDate1.isValid() &&
      formattedDate1.format("DD.MM.YYYY") === filteredValue
    ) {
      return true;
    }
    // Formatni "D.M.YYYY" formatga tekshiramiz
    const formattedDate2 = moment(asd?.day, "D.M.YYYY");
    if (
      formattedDate2.isValid() &&
      formattedDate2.format("D.M.YYYY") === filteredValue
    ) {
      return true;
    }
    return false;
  });

  return (
    <LayoutWrapper>
      <div className="Search-None Search-Box">
        <div className="searchingBox  searchingBoxDoctor">
          <div className="search">
            <div>
              <FaUsers />-{clients.length}
            </div>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
              type="text"
              placeholder="Izlash..."
            />
            <SearchOutlined />
          </div>
        </div>
        <Table
          loading={!data}
          bordered={true}
          size="small"
          columns={columns}
          dataSource={filteredValue ? filterDay : data}
          pagination={false}
          rowKey={"key"}
        />
      </div>
    </LayoutWrapper>
  );
};

export default GetPatients;
