import React, { useEffect, useState } from "react";
import { Form, Button, Input, Space, Select, Row, Col, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import "./style.css";
import Selectt from "react-select";
import { FiX } from "react-icons/fi";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import { useUpdateStoryMutation } from "../../../redux/clientStores";
import axios from "../../../api";

function UpdatePotients({ user, setOpenUpdate, editID }) {
  const [form] = useForm();

  const [choseDoctor, setChoseDoctor] = useState("");
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [quarters, setQuarters] = useState([]);

  // RTK QUERIES
  let { data: all_Doctor } = useGetAllDoctorsQuery();
  let [updateStory] = useUpdateStoryMutation();

  let allDoctor = all_Doctor?.data || [];
  let sortedData = allDoctor?.filter((i) => i.docORrecep === "doctor");

  const data = [];
  for (const item of sortedData) {
    data.push({
      value: item._id,
      label: item.specialization + ` (${item.firstName + " " + item.lastName})`,
    });
  }

  useEffect(() => {
    if (user && user.length > 0) {
      const updateStory = user.find((item) => item._id === editID);
      if (updateStory) {
        form.setFieldsValue({
          clientFullname: updateStory.clientFullname,
          clientPhone: updateStory.clientPhone,
          lastname: updateStory.lastname,
          birthday: updateStory.birthday,
          temperature: updateStory.temperature,
          height: updateStory.height,
          weight: updateStory.weight,
          phone: updateStory.phone,
          doctorType: updateStory.doctorType,
          paySumm: choseDoctor.paySumm,
          address: updateStory.address,
        });
      }
    }
  }, [user, editID, form]);

  // // addressni olish
  let headers = {
    headers: {
      authentication: `Bearer ${localStorage.token}`,
    },
  };
  // viloyat
  useEffect(() => {
    axios
      .get("/regions/getRegions", headers)
      .then((res) => setRegions(res?.data))
      .catch((err) => console.log(err));
  }, []);
  // tuman
  const getDistricts = (regionID) => {
    axios
      .get(`/regions/getDistricts/${regionID}`, headers)
      .then((res) => setDistricts(res.data))
      .catch((err) => console.log(err));
  };
  // MFY
  const getQuarters = (districtID) => {
    axios
      .get(`/regions/getQuarters/${districtID}`, headers)
      .then((res) => setQuarters(res.data))
      .catch((err) => console.log(err));
  };

  const onFinish = (values) => {
    let address = {
      region: values.address.region?.value,
      district: values.address.district?.value,
      quarter: values.address.quarter?.value,
    };

    values.address = address;

    updateStory({ id: editID, body: values })
      .then((res) => {
        if (res?.data?.success) {
          message.success("Yangilash muvaffaqiyatli amalga oshirildi!");
          setOpenUpdate(false);
        }
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <div className="updateDoctor">
      <div className="editForm">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Bemor" name="clientFullname">
                <Input placeholder="Ism / familiay" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Telefon" name="clientPhone">
                <Input placeholder="Telefon" />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item label="Manzil" name="address">
                <Input placeholder="Manzil" />
              </Form.Item>
            </Col> */}

            <Col span={8}>
              <Form.Item label="Tug'ilgan sana" name="birthday">
                <Input type="date" placeholder="yil/oy/kun" />
              </Form.Item>
            </Col>
            {/* </Row>
          <Row gutter={16}> */}
            <Col span={3}>
              <Form.Item label="Tana harorati" name="temperature">
                <Input placeholder="Tana harorati" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Vazni" name="weight">
                <Input placeholder="Vazni" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Bo'yi" name="height">
                <Input placeholder="Bo'yi" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Doktor" name="doctorType">
                <Select
                  style={{ width: "100%" }}
                  placeholder="Doktorlar"
                  onChange={(value) => setChoseDoctor(value)}
                  options={data}
                  optionRender={(option) => (
                    <Space>
                      <span role="img" aria-label={data.label}>
                        {data.data.label}
                      </span>
                      {option.label}
                    </Space>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Viloyat"
                name={["address", "region"]}
                rules={[
                  {
                    required: true,
                    message: "Viloyatni tanlang",
                  },
                ]}
              >
                <Selectt
                  onChange={(e) => getDistricts(e.regionID)}
                  options={regions}
                  placeholder="Viloyat"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Tuman"
                name={["address", "district"]}
                rules={[
                  {
                    required: true,
                    message: "Tumanni tanlang",
                  },
                ]}
              >
                <Selectt
                  onChange={(e) => getQuarters(e.districtID)}
                  options={districts}
                  placeholder="Tuman"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="M.F.Y"
                name={["address", "quarter"]}
                rules={[
                  {
                    required: true,
                    message: "M.F.Y ni tanlang",
                  },
                ]}
              >
                <Selectt options={quarters} placeholder="M.F.Y" />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex", gap: "5px" }}>
            {/* <Form.Item> */}
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Taxrirlash
            </Button>
            <Button type="primary" danger onClick={() => setOpenUpdate(false)}>
              <FiX style={{ marginTop: "-4px", fontSize: "22px" }} />
            </Button>
            {/* </Form.Item> */}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default UpdatePotients;
