import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  // baseUrl: "http://localhost:8080",
  // baseUrl: "https://yasmina-backend.vercel.app/",
  baseUrl: "https://medme.abdujabborov.uz/",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("authentication", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const api = createApi({
  reducerPath: "splitApi",
  baseQuery: baseQueryWithRetry,
  tagTypes: [
    "GetDoctors",
    "GetClients",
    "GetRooms",
    "getStories",
    "GetReports",
    "getMonthly",
    "GetNurses",
    "GetSalaries",
    "GetBot",
    "getMainBalans",
    "GetKassa",
    "GetRoomsStories",
    "getPayments"
  ],
  endpoints: () => ({}),
});
