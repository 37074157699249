import React from 'react';
// import Barcode from 'react-barcode';
// import BarcodeReader from 'react-barcode-reader';
import './style.css';
import { useParams, useNavigate } from "react-router-dom";
import logo from '../../../../assets/img/logo.png'
import LayoutWrapper from '../../../../components/layout/Layout';
import { useGetStoriesQuery } from "../../../../redux/clientStores";


function QarzdorliList() {

  const { _id } = useParams();
  const history = useNavigate();

  let { data: stories } = useGetStoriesQuery();
  let dataStories = stories?.innerData || [];
  let filterStores = dataStories?.filter(
    (i) =>
      i?.clientID === _id &&
      i?.debtor !== false &&
      i?.view !== false &&
      i?.online === false
  );


  const data = {
    name: 'Азимов Абдулахадхон Ахматович',
    birthDate: '1995.06.02',
    phone: '+998937307555',
    id: '0007',
    gender: 'Мужчина',
    employeeInfo: {
      name: 'Azizmov A',
      dateTime: '10:30 / 30.12.2020'
    }
  }
  const patientData = data;
  const handleGoBack = () => {
    history(-1);
    history("/", { replace: true });
  };

  return (
    <LayoutWrapper>
      <div className="App_Qarz">
        <div className="header_Qarz">
          <img onClick={handleGoBack} width={400} src={logo} alt="GOMED Logo" className="logo_Qarz" />
          <div className="contact-info">
            <p>Алишер Навои Стреэт 36, Наманган Регион, Узбекистан</p>
            <p>https://medme.uz/</p>
            <p>info@medme.uz</p>
            <p>+998 94 557 95 55</p>

          </div>
        </div>


        <h2>Пациент ҳақида маълумот</h2>
        <table className="services">
          <thead>
            <tr>
              <th>Ф.И.О</th>
              <th>Туғилган сана</th>
              <th>Телефон</th>
              <th>ID коди</th>
              <th>Жинс</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{patientData.name}</td>
              <td>{patientData.birthDate}</td>
              <td>{patientData.phone}</td>
              <td>{patientData.id}</td>
              <td>{patientData.gender}</td>
            </tr>

          </tbody>
        </table>

        <h2>Хизматлар</h2>
        <table className="services">
          <thead>
            <tr>
              <th>Т/Р</th>
              <th>Номи</th>
              <th>Сони</th>
              <th>Нархи</th>
              <th>Жами</th>
            </tr>
          </thead>
          <tbody>
            {filterStores.map((service, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{service.doctorType}</td>
                <td>{1}</td>
                <td>{service.paySumm}</td>
                <td>{service.paySumm}</td>

              </tr>
            ))}
            <tr >
              <td style={{ textAlign: "center" }} colSpan={2.5}>Жами сумма</td>
              <td style={{ textAlign: "center" }} colSpan={3}>100.000 сўм</td>
            </tr>
          </tbody>
        </table>


        <div className="employee-info">
          <div>Ходим исми: <b>{data.employeeInfo.name}</b></div>
          <div>Сана ва вақт: <b>{data.employeeInfo.dateTime}</b></div>
        </div>

      </div>
    </LayoutWrapper>
  );
}

export default QarzdorliList;



// import React from "react";
// import { Table, Tabs } from "antd";
// import { useParams } from "react-router-dom";
// import { DoubleLeftOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
// import { useGetStoriesQuery } from "../../../../redux/clientStores";
// import { useGetAllRoomsQuery } from "../../../../redux/roomApi";
// import { useGetSingleUserQuery } from "../../../../redux/clientApi";
// import LayoutWrapper from "../../../../components/layout/Layout";
// import { CountingMoney } from "../../../../hook/countingMoney";
// import { NumberFormat } from "../../../../hook/NumberFormat";

// const SinglePageDebtor = () => {
//   const { _id } = useParams();
//   const history = useNavigate();

//   let { data: stories } = useGetStoriesQuery();
//   let dataStories = stories?.innerData || [];
//   let filterStores = dataStories?.filter(
//     (i) =>
//       i?.clientID === _id &&
//       i?.debtor !== false &&
//       i?.view !== false &&
//       i?.online === false
//   );

//   const { data: singleData } = useGetSingleUserQuery({ id: _id });
//   let singleUser = singleData?.data;
//   const { data: roomData } = useGetAllRoomsQuery();

//   let roomClients = [];
//   roomData?.innerData?.map((r) => {
//     r.capacity?.length
//       ? roomClients?.push(
//         ...r.capacity?.map((i) =>
//           i ? { ...i, pricePerDay: r.pricePerDay } : i
//         )
//       )
//       : roomClients.push();
//   });

//   const columns = [
//     {
//       title: "Sana",
//       dataIndex: "day",
//     },
//     {
//       title: "Doktor",
//       dataIndex: "doctorFullname",
//     },
//     {
//       title: "Yo'naltirildi",
//       dataIndex: "doctorType",
//     },
//     {
//       title: "Summa",
//       render: (_, item) =>
//         new Intl.NumberFormat().format(item?.paySumm) + " so'm",
//     },
//   ];

//   const roomColumn = [
//     {
//       title: "Bemor",
//       render: (_, i) => {
//         return i.firstname + " " + i.lastname;
//       },
//     },
//     {
//       title: "Boshlandi",
//       dataIndex: "dayOfTreatment",
//     },
//     {
//       title: "price",
//       render: (_, item) =>
//         NumberFormat(CountingMoney(item?.dayOfTreatment, item.pricePerDay)) +
//         " so'm",
//     },
//   ];

//   const handleGoBack = () => {
//     history(-1);
//     history("/", { replace: true });
//   };
//   return (
//     <LayoutWrapper>
//       <div className="debtorSingle">
//         <Tabs>
//           <Tabs.TabPane defaultActiveKey="0" tab="Ko'riklar" key={0}>
//             <Table
//               columns={columns}
//               dataSource={filterStores}
//               bordered
//               title={() => (
//                 <DoubleLeftOutlined
//                   onClick={handleGoBack}
//                   className="DoubleLeftOutlined"
//                 />
//               )}
//               rowKey={"_id"}
//               pagination={false}
//             />
//           </Tabs.TabPane>
//           <Tabs.TabPane defaultActiveKey="1" tab="Davolanish" key={1}>
//             <Table
//               columns={roomColumn}
//               dataSource={roomClients?.filter(
//                 (i) => i.idNumber === singleUser?.idNumber
//               )}
//               bordered
//               title={() => (
//                 <DoubleLeftOutlined
//                   onClick={handleGoBack}
//                   className="DoubleLeftOutlined"
//                 />
//               )}
//               rowKey={"_id"}
//               pagination={false}
//             />
//           </Tabs.TabPane>
//         </Tabs>
//       </div>
//     </LayoutWrapper>
//   );
// };

// export default SinglePageDebtor;








// import React from 'react';
// import './style.css';
// import { useParams } from "react-router-dom";
// import logo from '../../../../assets/img/logo.png'
// import LayoutWrapper from '../../../../components/layout/Layout';
// import { useGetSingleUserQuery } from '../../../../redux/clientApi';
// import { useGetStoriesQuery } from '../../../../redux/clientStores';
// import { useGetAllRoomsQuery } from '../../../../redux/roomApi';
// // import { CountingMoney } from '../../../hook/countingMoney';
// // import { NumberFormat } from '../../../hook/NumberFormat';

// const QarzdorliList = () => {
//   const { _id } = useParams();

//   // Fetching single user data
//   const { data: singleData } = useGetSingleUserQuery({ id: _id });
//   const singleUser = singleData?.data;

//   // Fetching stories data
//   const { data: stories } = useGetStoriesQuery();
//   const dataStories = stories?.innerData || [];
//   const filterStores = dataStories?.filter(
//     (i) =>
//       i?.clientID === _id &&
//       i?.debtor !== false &&
//       i?.view !== false &&
//       i?.online === false
//   );

//   // Fetching room data
//   const { data: roomData } = useGetAllRoomsQuery();
//   let roomClients = [];
//   roomData?.innerData?.map((r) => {
//     r.capacity?.length
//       ? roomClients?.push(
//         ...r.capacity?.map((i) =>
//           i ? { ...i, pricePerDay: r.pricePerDay } : i
//         )
//       )
//       : roomClients.push();
//   });

//   const totalSum = filterStores.reduce((acc, item, index) => acc + item?.paySumm, 0);

//   return (
//     <LayoutWrapper>
//       <div className="App_Qarz">
//         <div className="header_Qarz">
//           <img width={400} src={logo} alt="GOMED Logo" className="logo_Qarz" />
//           <div className="contact-info">
//             {/* <Barcode value={JSON.stringify(data.patientInfo)} />

//                         <BarcodeReader
//                             onError={handleError}
//                             onScan={handleScan}
//                         /> */}
//             <p>Алишер Навои Стреэт 36, Наманган Регион, Узбекистан</p>
//             <p>https://medme.uz/</p>
//             <p>info@medme.uz</p>
//             <p>+998 94 557 95 55</p>

//           </div>
//         </div>

//         <h2>Пациент ҳақида маълумот</h2>
//         <table className="services">
//           <thead>
//             <tr>
//               <th>Ф.И.О</th>
//               <th>Туғилган сана</th>
//               <th>Телефон</th>
//               <th>ID коди</th>
//               <th>Жинс</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{singleUser?.fullname}</td>
//               <td>{singleUser?.birthDate}</td>
//               <td>{singleUser?.phone}</td>
//               <td>{singleUser?.idNumber}</td>
//               <td>{singleUser?.gender}</td>
//             </tr>
//           </tbody>
//         </table>

//         <h2>Хизматлар</h2>
//         <table className="services">
//           <thead>
//             <tr>
//               <th>Т/Р</th>
//               <th>Номи</th>
//               <th>Сони</th>
//               <th>Нархи</th>
//               <th>Жами</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filterStores.map((item, index) => (
//               <tr key={item._id}>
//                 <td>{index + 1}</td>
//                 <td>{item.serviceName}</td>
//                 <td>{item.quantity}</td>
//                 <td>{new Intl.NumberFormat().format(item?.paySumm)} so'm</td>
//                 <td>{new Intl.NumberFormat().format(item?.paySumm)} so'm</td>
//               </tr>
//             ))}
//             <tr>
//               <td style={{ textAlign: "center" }} colSpan={2.5}>Жами сумма</td>
//               <td style={{ textAlign: "center" }} colSpan={3}>{new Intl.NumberFormat().format(totalSum)} so'm</td>
//             </tr>
//           </tbody>
//         </table>

//         <div className="employee-info">
//           <div>Ходим исми: <b>{singleUser?.employeeName}</b></div>
//           <div>Сана ва вақт: <b>{new Date().toLocaleString()}</b></div>
//         </div>
//       </div>
//     </LayoutWrapper>
//   );
// }

// export default QarzdorliList;





