import React, { useEffect, useState } from "react";
import "./Appointments.css";
import { Link } from "react-router-dom";
import { GiWeightScale } from "react-icons/gi";
import { GiBodyHeight } from "react-icons/gi";
import { LiaTemperatureHighSolid } from "react-icons/lia";
import { MdOutlineBloodtype } from "react-icons/md";
import { BiAnalyse } from "react-icons/bi";
import { Table, Button } from "antd";
import { BsDiagram3 } from "react-icons/bs";
import { MdOutlineBiotech } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import { useGetStoriesQuery } from "../../../redux/clientStores";
import { useGetAllUsersQuery } from "../../../redux/clientApi";
import LayoutWrapper from "../../../components/layout/Layout";
import imgNoData from "../../../assets/nodata.png";
import { PhoneNumberFormat } from "../../../hook/NumberFormat";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
import socket from "../../../socket";

// const headers = { transports: ["websocket"] };
// const socket = io("https://medme.abdujabborov.uz/", headers);

function Appointments() {
  const { volume, isMuted } = useSelector((state) => state.audio);
  const [audio] = useState(new Audio(require("../../../assets/ayfon-sms.mp3")));

  // useEffect(() => {}, [mergedData, audio, volume, isMuted]);

  let category = localStorage.getItem("doctorID");
  let { data: allClients } = useGetAllUsersQuery();
  let data = allClients?.data || [];
  let { data: doctor } = useGetAllDoctorsQuery();
  let { data: stories, refetch } = useGetStoriesQuery();
  const storiesData = stories?.innerData || [];

  const changeDoctors =
    doctor?.data?.find((i) => i?.idNumber === category) || [];

  let time = new Date();
  let today =
    time.getDate() + "." + (time.getMonth() + 1) + "." + time.getFullYear();

  const clientStores = storiesData?.filter(
    (i) =>
      i?.doctorIdNumber === category &&
      i.view === false &&
      i?.online === false &&
      i.day === moment(today, "DD.MM.YYYY").format("DD.MM.YYYY") &&
      i.payState === true
  );
  // Sort clientStores array by queueNumber field in ascending order
  clientStores.sort((a, b) => a.queueNumber - b.queueNumber);

  useEffect(() => {
    socket.on("patient_paid", (updatedPatient) => {
      refetch();
      audio.volume = volume;
      audio.muted = isMuted;
      audio
        .play()
        .catch((error) => console.error("Musiqani chalib bo‘lmadi:", error));
    });
    return () => socket.off("patient_paid");
  }, [refetch]);

  // Bemorlar sonini hisoblash
  function countVisits() {
    const visitCounts = [];
    for (const item of clientStores) {
      const clientID = item.clientID;
      let index = visitCounts.findIndex(
        (visitCount) => visitCount.clientID === clientID
      );
      if (index === -1) {
        index = visitCounts.length;
        visitCounts.push({ clientID, lengthUsers: 0 });
      }
      visitCounts[index].lengthUsers++;
    }
    return visitCounts;
  }
  const visitCounts = countVisits();

  const mergedData = visitCounts.map((client) => ({
    ...client,
    ...clientStores.find((info) => info.clientID === client.clientID),
  }));

  const Bmi = (weight, height) => {
    if (weight && height) {
      const heightInMeters = height / 100;
      const bmiValue = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      return bmiValue;
    }
  };

  const Style = {
    fontSize: "19px",
    display: "flex",
    alignItems: "center",
  };
  const changeSection = (item) => {
    if (changeDoctors?.type === "analis") {
      return (
        <Link to={`/analises/${item._id}`}>
          <Button style={Style} type="primary">
            <EyeOutlined />
          </Button>
        </Link>
      );
    }
    // else if (changeDoctors?.type === "fizioterapiya") {
    //   return (
    //     <Link to={`/fizioterapiya/${item._id}`}>
    //       <Button style={Style} type="primary">
    //         <EyeOutlined />
    //       </Button>
    //     </Link>
    //   );
    // }
    else if (
      changeDoctors?.type === "doctor" ||
      changeDoctors?.type === "fizioterapiya" ||
      changeDoctors?.type === "uzi" ||
      changeDoctors?.type === "massaj"
    ) {
      return (
        <Link to={`/appointments/${item._id}`}>
          <Button style={Style} type="primary">
            <EyeOutlined />
          </Button>
        </Link>
      );
    }
  };

  function changeType(object) {
    return (
      object.type !== "analis" &&
      object.type !== "fizioterapiya" &&
      object.type !== "diagnostica"
    );
  }

  const columns = [
    {
      title: "№",
      dataIndex: "queueNumber",
      key: "queueNumber",
      align: "center",
    },
    {
      title: "Xizmat turi",
      align: "center",
      render: (item) =>
        item.extraSpecialization ? item.doctorType : "Ko'rik uchun",
    },
    {
      title: "Bemor",
      dataIndex: "clientFullname",
      key: "clientFullname",
      render: (v) =>
        capitalizeFirstLetter(v.split(" ")[0]) +
        " " +
        capitalizeFirstLetter(v.split(" ")[1]),
    },
    {
      title: "Telefon",
      dataIndex: "clientPhone",
      key: "clientPhone",
      render: (clientPhone) => `+998${PhoneNumberFormat(clientPhone)}`,
    },
    {
      ...(changeType(changeDoctors) ? (
        {
          title: "Analiz",
          key: "analysis",
          render: (item) => (
            <div className="box-bmi">
              {item?.blood_analysis ||
              item?.urgent ||
              item?.biochemical_analysis ? (
                <>
                  {item?.blood_analysis && (
                    <span>
                      <div>
                        <MdOutlineBloodtype /> Qon tahlili{" "}
                        <FaCheck className="checkMark" />
                      </div>
                    </span>
                  )}
                  {item?.urgent && (
                    <span>
                      <div>
                        <BiAnalyse /> Peshob tahlili{" "}
                        <FaCheck className="checkMark" />
                      </div>
                    </span>
                  )}
                  {item?.biochemical_analysis && (
                    <span>
                      <div>
                        <MdOutlineBiotech /> Bioximik tahlili{" "}
                        <FaCheck className="checkMark" />
                      </div>
                    </span>
                  )}
                </>
              ) : (
                "Topshirmagan"
              )}
            </div>
          ),
        }
      ) : (
        <></>
      )),
    },
    {
      ...(changeType(changeDoctors) ? (
        {
          title: "diagnostika",
          dataIndex: "diagnostics",
          key: "diagnostics",
          render: (diagnostics) =>
            changeType(changeDoctors) && (
              <div className="box-bmi">
                {diagnostics ? (
                  <span>
                    <div>
                      <BsDiagram3 className="Diagram" /> {diagnostics}
                    </div>
                  </span>
                ) : (
                  "O'tmagan"
                )}
              </div>
            ),
        }
      ) : (
        <></>
      )),
    },
    {
      title: "Tana massa indeksi",
      key: "bmi",
      render: (item) => (
        <>
          {item?.weight && item?.height ? (
            <div className="box-bmi">
              <span>
                <div>
                  <GiWeightScale /> {item?.weight} kg
                </div>
                <div>
                  <GiBodyHeight /> {item?.height} m
                </div>
              </span>
              <span>
                <div>
                  <LiaTemperatureHighSolid /> {item?.temperature}
                </div>
                <div> BMI {Bmi(+item?.weight, +item?.height)}</div>
              </span>
            </div>
          ) : (
            "Topshirmagan"
          )}
        </>
      ),
    },
    {
      title: "Qabul qilish",
      key: "action",
      render: (item) => changeSection(item),
    },
    {
      title: "Tashrifi",
      dataIndex: "lengthUsers",
      key: "lengthUsers",
      align: "center",
    },
  ];

  return (
    <LayoutWrapper>
      <h3 className="text-center">Bemorlar</h3>
      {mergedData === 0 ? (
        <div className="NoData">
          <div className="NoDataImg">
            <img src={imgNoData} alt="No Data" />
          </div>
        </div>
      ) : (
        <Table
          rowKey={"_id"}
          loading={!data ? true : false}
          bordered={true}
          size="small"
          dataSource={mergedData}
          columns={columns}
          pagination={false}
        />
      )}
    </LayoutWrapper>
  );
}

export default Appointments;
