import React, { useEffect, useMemo, useState } from "react";
import "./UpdateRoom.css";
import { useParams, Link } from "react-router-dom";
import { Button, Table } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { NumberFormat } from "../../../../hook/NumberFormat";
import { useGetAllUsersQuery } from "../../../../redux/clientApi";
import { capitalizeFirstLetter } from "../../../../hook/CapitalizeFirstLitter";
import { PhoneNumberFormat } from "../../../../hook/NumberFormat";
import { ColculateBorthday } from "../../../../hook/ColculateBorthday";
import { IdCapitalize } from "../../../../hook/IDCapitalize";
import { useGetAllRoomsQuery } from "../../../../redux/roomApi";
import LayoutWrapper from "../../../../components/layout/Layout";
import SearchBar from "../../../../components/SearchComponent";

function UpdateRoom() {
  const [roomFull, setRoomFull] = useState(false);
  const [currentPage] = useState(1);
  const { data: rooms } = useGetAllRoomsQuery();
  const roomData = rooms?.innerData;
  const [query, setQuery] = useState("");

  let { _id } = useParams();

  // ----------------------------

  const room = roomData?.find((item) => item._id === _id);

  const { data: users = [] } = useGetAllUsersQuery({
    pageParam: currentPage,
    searchQuery: query,
  });

  let AllData = useMemo(() => users?.data || [], [users?.data]);
  AllData = AllData.filter((i) => i.treating !== true);

  useEffect(() => {
    if (room?.usersNumber === room?.capacity?.length)
      return setRoomFull(!roomFull);
  }, [room, roomFull]);

  const Style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
  };

  const columnsUsers = [
    {
      title: "Bemor",
      dataIndex: "fullName",
      render: (text, record) => (
        <span>
          {capitalizeFirstLetter(record.lastname)}{" "}
          {capitalizeFirstLetter(record.firstname)}
        </span>
      ),
    },
    {
      title: "ID Raqami",
      dataIndex: "idNumber",
      render: (text) => (
        <p style={{ color: text ? "black" : "red" }}>
          {text ? IdCapitalize(text) : "Topilmadi"}
        </p>
      ),
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      render: (text) => PhoneNumberFormat(text),
    },
    {
      title: "Yoshi",
      dataIndex: "year",
      render: (text) => ColculateBorthday(text),
    },
    {
      title: "Manzili",
      dataIndex: "address",
      render: (user) => user.region,
    },
    {
      title: "Xonaga joylash",
      dataIndex: "_id",
      render: (text, record) => (
        <Link to={`/addUseToRoom/${record._id}/${room?._id}`} >
          <Button
            type="primary"
            style={Style}
          >
            <PlusCircleOutlined />
          </Button>
        </Link >
      ),
    },
  ];


  return (
    <LayoutWrapper>
      <div className="updateRoom_wrapper">
        <div className="updateRoom_captionMain">
          <h2>
            <b>{room?.roomNumber}</b>-xona
          </h2>
          <h2>
            <b>{room?.category === "luxury" ? "Lyuks" : "Polulyuks"}</b>
          </h2>
          <h2>
            <b>{room?.floor}</b>-qavat
          </h2>
        </div>
        <div className="updateRoom_main">
          <div className="updateRoom_titleMain">
            <b>
              Xona sigimi: <b>{room?.usersNumber}</b> ta
            </b>
            <b>
              Xonadagi bemorlar soni: <b>{room?.capacity?.length}</b> ta
            </b>
            <b>
              Bir kunlik to'lov:{" "}
              <span>{NumberFormat(room?.pricePerDay)} so'm</span>
            </b>
          </div>
          <SearchBar query={query} setQuery={setQuery} data={AllData} />
          <Table
            dataSource={AllData}
            bordered={true}
            size="small"
            loading={!AllData ? true : false}
            pagination={false} // Disable default pagination
            columns={columnsUsers}
            rowKey="_id"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </LayoutWrapper>
  );
}

export default UpdateRoom;


