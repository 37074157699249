import { FaCalculator, FaChartPie, FaUserDoctor } from "react-icons/fa6";
import {
  FaUniversity,
  FaBed,
  FaUserPlus,
  FaRegChartBar,
  FaCog,
  FaAddressCard,
  FaUsers,
  FaUserNurse,
  FaRegMoneyBillAlt,
  FaAlignCenter,
  FaFileAlt,
  FaCalendarAlt,
  FaList,
  FaHistory,
  FaMedkit,
} from "react-icons/fa";

export const onerMenu = [
  {
    name: "Asosiy",
    path: "/reports",
    icon: <FaChartPie />,
  },
  {
    name: "Xodimlar",
    path: "/xodimlar",
    icon: <FaUserDoctor />,
  },

  {
    name: "Hisobotlar",
    path: "/xisobotlar",
    icon: <FaUniversity />,
  },
  {
    name: "Davolanish",
    path: "/viewRoom",
    icon: <FaBed />,
  },
  {
    name: "Ishga qabul qilish",
    path: "/add-doctor",
    icon: <FaUserPlus />,
  },
  {
    name: "Statistika",
    path: "/statistic",
    icon: <FaRegChartBar />,
  },
  {
    name: "Tizim",
    path: "/setting",
    icon: <FaCog />,
  },
];

export const adminMenu = [
  {
    name: "Qabul Bo'limi",
    path: "/receptionHome",
    icon: <FaAddressCard />,
  },
  {
    name: "Bemorlar",
    path: "/patients",
    icon: <FaUsers />,
  },
  {
    name: "Davolanish",
    path: "/cabins",
    icon: <FaBed />,
  },
  {
    name: "Doktorlar",
    path: "/admin/doctors",
    icon: <FaUserDoctor />,
  },
  {
    name: "Hamshiralar",
    path: "/nurse",
    icon: <FaUserNurse />,
  },
];

export const KesserMenu = [
  {
    name: "Qabul Bo'limi",
    path: "/cashier-reception",
    icon: <FaAddressCard />,
  },
  {
    name: "Xarajatlar",
    path: "/expenses",
    icon: <FaRegMoneyBillAlt />,
  },
  {
    name: "Qarzdorlar",
    path: "/debtors",
    icon: <FaAlignCenter />,
  },
  {
    name: "Davolanish",
    path: "/cabins",
    icon: <FaBed />,
  },
  {
    name: "Hisobotlar",
    path: "/xisobotlar",
    icon: <FaFileAlt />,
  },
  {
    name: "Kassa yopish",
    path: "/daily-report",
    icon: <FaCalendarAlt />,
  },
  {
    name: "Statistika",
    path: "/statistic",
    icon: <FaRegChartBar />,
  },
];

export const CalCenter = [
  {
    name: "Barcha bemorlar",
    path: "/appointments_calCenter",
    icon: <FaUsers />,
  },
  {
    name: "Aloqa tarixi",
    path: "/calCenter_story",
    icon: <FaHistory />,
  },
];

let doctorType = localStorage.getItem("doctorType") || {};
let category = localStorage.category;
export const doctorMenu = [
  {
    name: "Bemorlar",
    path: "/appointments",
    icon: <FaList />,
  },
  ...(doctorType === "doctor"
    ? [
        {
          name: "Davolanish",
          path: "/doctor-patients",
          icon: <FaBed />,
        },
      ]
    : []),
  ...(category === "Kardiolog"
    ? [
        {
          name: "Ambulatoriya",
          path: "/ambulatoriya",
          icon: <FaFileAlt />,
        },
      ]
    : []),
  {
    name: "Arxiv",
    path: "/doctor/patients_history",
    icon: <FaHistory />,
  },
  {
    name: "Kashelyok",
    path: "/kashelyok",
    icon: <FaRegMoneyBillAlt />,
  },
  ...(doctorType === "fizioterapiya"
    ? [
        {
          name: "Barcha muolajalar",
          path: "/medical-fiziyo",
          icon: <FaMedkit />,
        },
      ]
    : []),
  {
    name: "Kalkulyatorlar",
    path: "/medical-calculators",
    icon: <FaCalculator />,
  },
  ...(doctorType === "analis"
    ? [
        {
          name: "Muolajalar",
          path: "/analis/additional",
          icon: <FaMedkit />,
        },
      ]
    : [
        {
          name: "Muolajalar",
          path: "/additional-treatments",
          icon: <FaMedkit />,
        },
      ]),
];
