import React, { useState, useEffect } from "react";
import {
  Button,
  message,
  Space,
  // Modal,
  Pagination,
  Table,
  InputNumber,
  // Popconfirm
} from "antd";
import { NavLink } from "react-router-dom";
import {
  PlusCircleOutlined,
  // CheckOutlined,
  EyeOutlined,
} from "@ant-design/icons";
// import { useDispatch } from "react-redux";
// import { ExclamationCircleFilled } from "@ant-design/icons";
import { NumberFormat, PhoneNumberFormat } from "../../../../hook/NumberFormat";
import "./style.css";
import { useGetUsersQuery } from "../../../../redux/clientApi";
import { useUpdateClientMutation } from "../../../../redux/clientApi";
import { useGetStoriesQuery } from "../../../../redux/clientStores";
import { useUpdateStoryMutation } from "../../../../redux/clientStores";
import { useGetAllRoomsQuery } from "../../../../redux/roomApi";
import { capitalizeFirstLetter } from "../../../../hook/CapitalizeFirstLitter";
// import { setLength } from "../../../../redux/recordList/ClientLength";
import { CountingMoney } from "../../../../hook/countingMoney";

const QarzdorlarBolimi = ({ query }) => {
  // const { confirm } = Modal;
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Sahifalar soni
  const { data: users = [] } = useGetUsersQuery();

  const { data: roomData } = useGetAllRoomsQuery();
  // const dispatch = useDispatch();
  let roomClients = [];
  roomData?.innerData?.map((r) => {
    r.capacity.length
      ? roomClients.push(
          ...r.capacity.map((i) =>
            i ? { ...i, pricePerDay: r.pricePerDay } : i
          )
        )
      : roomClients.push();
  });

  useEffect(() => {
    if (users.success) {
      setTotalPages(Math.ceil(users.totalClients));
    }
  }, [users]);

  const clients = users?.data || [];
  const [updateStory] = useUpdateStoryMutation();
  const clientData = clients?.filter((i) => i.debtor === true);

  const [updateClient] = useUpdateClientMutation();
  const { data: stories } = useGetStoriesQuery();
  const dataStories = stories?.innerData || [];

  const columns = [
    {
      title: "Bemor",
      dataIndex: "firstname",
      render: (_, user) =>
        capitalizeFirstLetter(user.firstname + " " + user.lastname),
      // user.firstname + " " + user.lastname,
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      render: (phone) => "+999 " + PhoneNumberFormat(phone),
    },
    {
      title: "Manzil",
      dataIndex: "address",
      render: (address) => capitalizeFirstLetter(address?.region),
      // render: (address) => address.region,
    },
    {
      title: "Qarzdorlik",
      dataIndex: "totalPrice",
      render: (_, item) => {
        let room = roomClients?.find((i) => i.idNumber === item.idNumber);
        let roomMoney = room
          ? CountingMoney(room?.dayOfTreatment, room?.pricePerDay)
          : 0;
        let total = item.totalPrice + roomMoney;
        return NumberFormat(total);
      },
    },
    {
      title: "To'landi",
      dataIndex: "totalAmountPaid",
      render: (totalAmountPaid) =>
        NumberFormat(totalAmountPaid) + (totalAmountPaid && " so'm"),
    },
    {
      title: "Ayrish",
      render: (_, record) => (
        <Space>
          <InputNumber
            onChange={(e) => setTotalAmountPaid(e)}
            placeholder="Summani kiriting"
          />
          <Button
            type="primary"
            className="btnAddMoney"
            onClick={() => {
              updateDebtorPrice(record);
            }}
          >
            <PlusCircleOutlined />
          </Button>
        </Space>
      ),
    },
    {
      title: "Tarix",
      dataIndex: "history",
      render: (_, record) => (
        <Space>
          <NavLink to={`/userDebtorSinglePage/${record._id}`}>
            <Button type="primary" className="btnAddMoney">
              <EyeOutlined />
            </Button>
          </NavLink>
        </Space>
      ),
    },
  ];

  function updateDebtorPrice(item) {
    let info = { ...item };

    info.totalAmountPaid = info.totalAmountPaid + parseFloat(totalAmountPaid);
    updateClient({ id: info._id, body: info })
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
          setTotalAmountPaid(0);
        }
      })
      .catch((err) => console.error("err", err));

    let updateStores = dataStories?.find(
      (i) => i?.clientID === item?._id && i?.debtor === true
    );

    if (info?.totalAmountPaid >= info?.totalPrice) {
      info.debtor = false;
      info.totalPrice = 0;
      info.totalAmountPaid = 0;

      updateClient({ id: info._id, body: info })
        .then((res) => {
          if (res?.data?.success) {
            message.success("Barcha qarzdorlik to'landi!");
            setTotalAmountPaid(0);
          }
        })
        .catch((err) => console.log("err", err));

      let update = {
        ...updateStores,
        debtor: false,
      };

      updateStory({ id: updateStores._id, body: update })
        .then((res) => {
          if (res?.data?.success) {
            message.success(res.data.message);
            setTotalAmountPaid(0);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <Table
        rowKey={"_id"}
        loading={!clientData ? true : false}
        bordered={true}
        size="small"
        columns={columns}
        dataSource={clientData}
        pagination={false}
      />
      {clientData?.length > 10 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "4px 0",
          }}
        >
          <Pagination
            current={currentPage}
            total={totalPages}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default QarzdorlarBolimi;
