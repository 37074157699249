import React, { useEffect, useMemo, useState } from "react";
import { message, Button, Modal, Select } from "antd";
import moment from "moment";
import {
  PlusOutlined,
  ExclamationCircleFilled,
  UpOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { GiEntryDoor } from "react-icons/gi"; // react-icons librarydan foydalanib GiEntryDoor ni import qildik
import { useNavigate, useParams } from "react-router-dom";
import { NumberFormat, PhoneNumberFormat } from "../../../../hook/NumberFormat";
import {
  useDeleteUserFromRoomMutation,
  useGetAllRoomsQuery,
  useUpdateRoomMutation,
} from "../../../../redux/roomApi";

import {
  useCreatePaymentStoryMutation
} from "../../../../redux/paymentApi";
import socket from '../../../../socket'

import {
  useGetRoomStoriesQuery,
  useCreateRoomStoriesMutation,
  useUpdateRoomStoriesMutation,
  useUpdateRoomPaymentMutation,
  useUpdatePaidDaysMutation,
} from "../../../../redux/roomStoriesApi";
import { capitalizeFirstLetter } from "../../../../hook/CapitalizeFirstLitter";
import { CountingDay } from "../../../../hook/ColculateDay";
import { calculateRoomPayment } from "../../../../hook/reduceRoomPrice";
import LayoutWrapper from "../../../../components/layout/Layout";
import RoomModal from "../../../../components/checkLists/roomModal/RoomModal";
import "./style.css";


function EnterRoom() {
  const [roomModalState, setRoomModalState] = useState(false);
  const [additionalPrice, setAdditionalPrice] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [debtPayment, setDebtPayment] = useState({});
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isTopIconArray, setIsTopIconArray] = useState([]); // Har bir element uchun holat

  let navigate = useNavigate();
  let { id } = useParams();
  const { data: rooms } = useGetAllRoomsQuery();
  const allRooms = rooms?.innerData || [];

  let room = allRooms?.find((r) => r._id === id);

  const [updateRoomFunc] = useUpdateRoomMutation();
  const [createPaymentStory] = useCreatePaymentStoryMutation();
  const [createRoomStory] = useCreateRoomStoriesMutation();
  const [updateRoomStory] = useUpdateRoomStoriesMutation();
  let { data: roomStories, refetch } = useGetRoomStoriesQuery();
  const [updateRoomPayment] = useUpdateRoomPaymentMutation();
  const [updatePaidDays] = useUpdatePaidDaysMutation();

  const [deleteUserFromRoom] = useDeleteUserFromRoomMutation();
  const todaysTime = useMemo(
    () => moment().utcOffset("+05:00").format("DD.MM.YYYY HH:mm"),
    []
  );
  const { confirm } = Modal;

  const OutInRoom = (clientID, roomID, userRoomPay, record) => {
    // Aktif bo'lgan xonani va bemorni topamiz
    const activeRoomStories = roomStories?.innerData
      .filter((i) => i.active === true && i.clientID === record?.clientID)
      .map((i) =>
        i.endDay === "0"
          ? {
            ...i,
            endDay: todaysTime,
            clientPayForRoomPrice: calculateRoomPayment(record),
          }
          : i
      );

    // Har bir kun uchun to'lovning to'liq to'lanmaganligini tekshirish
    const hasUnpaidDays = activeRoomStories.some((story) =>
      story.paidDays?.some((day) => !day.isPaid && day.price > 0)
    );

    if (hasUnpaidDays) {
      return message.warning("Bemorning to'lamagan qarzlari bor. Avval to'lovni amalga oshiring.");
    }

    const totalPayForRoom = activeRoomStories.reduce(
      (a, b) => a + b.clientPayForRoomPrice,
      0
    );

    setRoomModalState({ activeRoomStories, record, totalPayForRoom });

    confirm({
      title: "Bemorni honadan chiqarmoqchimisiz?",
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk() {
        deleteUserFromRoom({
          clientID,
          roomID,
          body: activeRoomStories,
        })
          .then((res) => {
            if (res?.data?.success) {
              message?.success(res.data.message);
            }
          })
          .catch((err) => console.log(err));
      },
    });
  };


  const buttonStyle = useMemo(
    () => ({
      fontSize: "18px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }),
    []
  );

  const iconStyle = useMemo(
    () => ({
      fontSize: "20px",
    }),
    []
  );

  // let doctorType = "room";
  const getCategoryLabel = (category) => {
    switch (category) {
      case "pollux":
        return "Polyuks";
      case "luxury":
        return "Lyuks";
      case "free":
        return "Oddiy";
      default:
        return category;
    }
  };

  const data = allRooms
    ?.filter((r) => r.capacity?.length !== r.usersNumber && r._id !== id)
    ?.map((room) => ({
      value: room._id,
      label: "[" + room.roomNumber + "] " + getCategoryLabel(room.category),
    }));


  // xona capacitysida bor bemorlarni ajratib olish
  let capacity = room?.capacity;
  const roomData = roomStories?.innerData.filter(
    (i) =>
      i?.roomNumber === room?.roomNumber &&
      i.active === true &&
      i.endDay === "0" &&
      capacity.some(
        (u) => u.idNumber === i.clientID || u.clientID === i.clientID
      )
  );

  useEffect(() => {
    socket.on('updateRoomPayment', () => {
      refetch()
    })
  }, [refetch])

  // bemorni xonasini almashtirish
  const addUserToRoom = (id, userInfo) => {
    console.log(userInfo);
    let user = { ...userInfo };
    // yangi xonani topish
    let xona = { ...allRooms.find((r) => r._id === id) };
    confirm({
      title: `Bemorni ${xona.roomNumber}-xonaga ko'chirmoqchimisiz?`,
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk() {
        setTimeout(() => {
          // yangi xona sigimi
          let capacity = [...xona.capacity];
          // avvaldan bormi tekshirish
          if (
            capacity.some((u) =>
              u.idNumber
                ? u.idNumber === user.clientID
                : u.clientID === user.clientID
            )
          ) {
            return message.warning("Bemor xonada avvaldan mavjud");
          }
          user.dayOfTreatmen = moment().format("DD.MM.YYYY");
          capacity.push(user);
          xona.capacity = capacity;
          // yangi xonaga bemorni biriktirish uchun
          updateRoomFunc({ id: xona._id, body: xona })
            .then((res) => {
              if (res?.data?.success) {
                return message.success(res?.data?.message);
              }
              message.warning(res?.data?.msg);
            })
            .catch((err) => console.log("err>>", err));
          // eski xonadan bemorni ochirish
          let filteredCapacity = room?.capacity?.filter((u) =>
            u.idNumber
              ? u.idNumber !== user.clientID
              : u.clientID !== user.clientID
          );
          let oldRoomInfo = { ...room, capacity: filteredCapacity }; // eski holatni tozalash
          // eski xonadan bemorni ochirish jarayoni
          updateRoomFunc({ id: room._id, body: oldRoomInfo })
            .then((res) => {
              if (res?.data?.success) {
                return message.success(res?.data?.message);
              }
              message.warning(res?.data?.msg);
            })
            .catch((err) => console.log("err>>", err));

          // ----------------------------
          // eski xona storysini update qilish
          let oldRoomStory = {
            ...userInfo,
            endDay: todaysTime,
            clientPayForRoomPrice: calculateRoomPayment(userInfo),
          };

          updateRoomStory({ id: oldRoomStory._id, body: oldRoomStory })
            .then((res) => {
              if (res?.data?.success) {
                return message.success(res?.data?.message);
              }
              message.warning(res?.data?.message);
            })
            .catch((err) => {
              console.log(err);
            });

          // yangi xona uchun yangi story yaratish
          let roomStory = {
            ...userInfo,
            startDay: todaysTime,
            floor: xona.floor,
            roomNumber: xona.roomNumber,
            payForRoom: xona.pricePerDay,
          };

          let { _id, ...rest } = roomStory;

          createRoomStory(rest)
            .then((res) => {
              if (res?.data?.success) {
                return message.success(
                  `Bemor {${room?.roomNumber}}-xonaga mofaqiyatli joylandi!`
                );
              }
              message.warning(res.error.data.message.message);
            })
            .catch((err) => console.log("err>>", err));
          // eski xonada odam qolmasa xonalar sahifasiga yonaladi
          if (!filteredCapacity.length) {
            navigate(-1);
          }
        }, 0);
      },
    });
  };

  // const addUserToRoom = (id, userInfo) => {
  //   console.log(userInfo);

  //   // Set splitDate to today's date using moment
  //   const splitDate = moment().format('DD.MM.YYYY');

  //   let user = { ...userInfo };
  //   // yangi xonani topish
  //   let xona = { ...allRooms.find((r) => r._id === id) };

  //   confirm({
  //     title: `Bemorni ${xona.roomNumber}-xonaga ko'chirmoqchimisiz?`,
  //     icon: <ExclamationCircleFilled />,
  //     okText: "Ha",
  //     okType: "danger",
  //     cancelText: "Yo'q",
  //     onOk() {
  //       setTimeout(() => {
  //         // yangi xona sigimi
  //         let capacity = [...xona.capacity];
  //         // avvaldan bormi tekshirish
  //         if (
  //           capacity.some((u) =>
  //             u.idNumber
  //               ? u.idNumber === user.clientID
  //               : u.clientID === user.clientID
  //           )
  //         ) {
  //           return message.warning("Bemor xonada avvaldan mavjud");
  //         }

  //         // Sana bo'yicha `paidDays` tarixini bo'lish
  //         let oldPaidDays = user.paidDays.filter((day) =>
  //           moment(day.date).isSameOrBefore(moment(splitDate))
  //         );
  //         let newPaidDays = user.paidDays.filter((day) =>
  //           moment(day.date).isAfter(moment(splitDate))
  //         );

  //         // Eski xonada qoldiriladigan user malumoti (faqat eski `paidDays` qismi)
  //         let oldUser = { ...user, paidDays: oldPaidDays };

  //         // Yangi xonaga o'tkaziladigan user malumoti (faqat yangi `paidDays` qismi)
  //         let newUser = { ...user, paidDays: newPaidDays, dayOfTreatment: moment().format("DD.MM.YYYY") };

  //         // Yangi bemorni yangi xonaga qo'shish
  //         capacity.push(newUser);
  //         xona.capacity = capacity;
  //         updateRoomFunc({ id: xona._id, body: xona })
  //           .then((res) => {
  //             if (res?.data?.success) {
  //               return message.success(res?.data?.message);
  //             }
  //             message.warning(res?.data?.msg);
  //           })
  //           .catch((err) => console.log("err>>", err));

  //         // Eski xonadan bemorni yangilash (faqat eski `paidDays` qismi qoldiriladi)
  //         let filteredCapacity = room?.capacity?.map((u) =>
  //           u.clientID === user.clientID ? oldUser : u
  //         )
  //         // ?.filter((u) =>
  //         //   u.idNumber
  //         //     ? u.idNumber !== user.clientID
  //         //     : u.clientID !== user.clientID
  //         // );
  //         let oldRoomInfo = { ...room, capacity: filteredCapacity }; // eski holatni tozalash
  //         updateRoomFunc({ id: room._id, body: oldRoomInfo })
  //           .then((res) => {
  //             if (res?.data?.success) {
  //               return message.success(res?.data?.message);
  //             }
  //             message.warning(res?.data?.msg);
  //           })
  //           .catch((err) => console.log("err>>", err));

  //         // Eski xona storysini yangilash
  //         let oldRoomStory = {
  //           ...oldUser,
  //           endDay: todaysTime,
  //           clientPayForRoomPrice: calculateRoomPayment(oldUser),
  //         };

  //         updateRoomStory({ id: oldRoomStory._id, body: oldRoomStory })
  //           .then((res) => {
  //             if (res?.data?.success) {
  //               return message.success(res?.data?.message);
  //             }
  //             message.warning(res?.data?.message);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });

  //         // Yangi xona uchun yangi story yaratish
  //         let roomStory = {
  //           ...newUser,
  //           startDay: todaysTime,
  //           floor: xona.floor,
  //           roomNumber: xona.roomNumber,
  //           payForRoom: xona.pricePerDay,
  //         };

  //         let { _id, ...rest } = roomStory;

  //         createRoomStory(rest)
  //           .then((res) => {
  //             if (res?.data?.success) {
  //               return message.success(
  //                 `Bemor {${room?.roomNumber}}-xonaga mofaqiyatli joylandi!`
  //               );
  //             }
  //             message.warning(res.error.data.message.message);
  //           })
  //           .catch((err) => console.log("err>>", err));

  //         // Eski xonada odam qolmasa xonalar sahifasiga yonaladi
  //         if (!filteredCapacity.length) {
  //           navigate(-1);
  //         }
  //       }, 0);
  //     },
  //   });
  // };

  // Sana formatini "YYYY-MM-DD" qilib saqlash uchun funksiya

  const formatDateForServer = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 0-indekslash
    const year = date.getFullYear();
    return `${year}-${month}-${day}`; // ISO format: "YYYY-MM-DD"
  };

  // Arifmetik amalni inputdan olish uchun yangi funksiya
  const handleArithmeticOperation = (value, input) => {
    // const operation = input.match(/(\d+)([\+\-])(\d+)/); 
    const operation = input.match(/^(\d+)([\+\-])(\d+)$/);// 5+2 yoki 5-2 formatidagi amalni olish
    if (!operation) {
      return; // Agar amal topilmasa hech narsa qilmaymiz
    }

    let [, , operator, amount] = operation;

    amount = parseInt(amount, 10);

    // Sana qo'shish va serverga saqlash logikasi
    let lastDate = value.paidDays?.length
      ? new Date(value.paidDays[value.paidDays.length - 1].date.split('.').reverse().join('-')) // "DD.MM.YYYY" ni ISO formatga aylantirish
      : new Date(); // Bo'sh bo'lsa, bugungi sanadan boshlanadi

    if (isNaN(lastDate.getTime())) {
      lastDate = new Date(); // Agar invalid date bo'lsa, bugungi sanani oladi
    }


    if (operator === "+") {
      // Qo'shish amali
      let updatedPaidDays = [...value.paidDays];

      updatedPaidDays = updatedPaidDays.concat(
        Array.from({ length: amount }, (_, index) => {
          lastDate.setDate(lastDate.getDate() + 1); // Sana ketma-ket oshiriladi
          const newDate = new Date(lastDate); // Yangi sanani nusxalash
          return {
            day: updatedPaidDays.length + index + 1, // Raqamni ketma-ketlikda qo'shish
            price: 0,
            isPaid: false,
            date: formatDateForServer(newDate), // Serverga saqlash uchun format
          };
        })
      );

      return updatedPaidDays;
    } else if (operator === "-") {
      // Ayirish amali
      let updatedPaidDays = [...value.paidDays];
      updatedPaidDays.splice(-amount, amount); // Oxiridan amount miqdorda elementlarni olib tashlaymiz
      return updatedPaidDays;
    }

    return value.paidDays; // Agar hech narsa topilmasa eski holat qaytariladi
  };


  // Boshqa funksiyalarni o'zgartirmasdan, yangi operatsiyani ishlatish
  const handleDebtPaymentChange = (e, value) => {
    const valueInput = e.target.value;

    // Regulyar ifoda - haqiqiy qiymatlarni tanlash
    const validPattern = /^\d+([\+\-]\d+)*$/;

    // Kiritilgan qiymatni tekshirish
    if (validPattern.test(valueInput)) {
      const payment = parseFloat(valueInput);
      console.log('To\'lov miqdori:', payment);

      if (payment <= 0) {
        return message.error("To'lov miqdori musbat bo'lishi kerak");
      }

      const payForRoom = value?.payForRoom; // Har bir kunning to'lov miqdori

      // Optimizatsiya qilingan paidDays amalini tekshirish
      let updatedPaidDays = handleArithmeticOperation(value, valueInput);
      if (updatedPaidDays) {
        setDebtPayment({ ...debtPayment, [value.clientID]: updatedPaidDays });
        setInputValue(valueInput);
        return;
      }

      let remainingDebt = value?.paidDays?.filter((day) => !day.isPaid); // Faqat isPaid: false bo'lgan kunlar

      if (!remainingDebt.length) {
        return message.info("Qarz mavjud emas, barcha kunlar to'langan");
      }

      // To'lov miqdorini aniqlash
      const calculateTotalDebt = (remainingDebt) => {
        return remainingDebt.reduce((sum, day) => {
          return sum + (payForRoom - day.price);
        }, 0);
      };

      let totalDebt = calculateTotalDebt(remainingDebt);

      if (payment > totalDebt) {
        setIsInputDisabled(true);
        return message.error("To'lov miqdori umumiy qarzdan ko'p bo'la olmaydi");
      } else {
        setIsInputDisabled(false);
      }

      let remainingPayment = payment; // Qolgan to'lov miqdori

      let updatedPayments = remainingDebt.map((day) => {
        if (remainingPayment <= 0) {
          return day; // Agar to'lov qolmagan bo'lsa, kunni o'zgartirmasdan qaytaramiz
        }

        let newPrice;
        let isPaid;

        // Har bir kun uchun maksimal to'lov miqdorini hisoblaymiz
        let maxPaymentForDay = Math.min(payForRoom - day.price, remainingPayment);

        newPrice = day.price + maxPaymentForDay; // To'lovni mavjud price ga qo'shamiz
        remainingPayment -= maxPaymentForDay; // To'langan miqdorni kamaytiramiz
        isPaid = newPrice >= payForRoom; // Agar to'liq to'langan bo'lsa, isPaid true bo'ladi

        return {
          ...day,
          price: newPrice,
          isPaid: isPaid,
        };
      });

      setDebtPayment({ ...debtPayment, [value.clientID]: updatedPayments });
      setAdditionalPrice(payment);
    } else {
      console.log('Noto\'g\'ri formatdagi qiymat.');
    }
  };

  // --------handlePayDebt-----------
  const handlePayDebt = async (item) => {
    const payments = debtPayment[item.clientID];

    if (!payments || !payments.length) {
      return message.warning("Iltimos, to'lov miqdorini kiriting.");
    }

    console.log(additionalPrice);
    const clientPrice = {
      clientPayForRoomPrice: item.clientPayForRoomPrice + additionalPrice, // To'lov miqdorini yangilash
    };


    //     // inputValue mavjudligi va string ekanligi tekshiriladi
    const arithmeticOperation = (inputValue && typeof inputValue === 'string')
      ? inputValue.match(/^(\d+)([\+\-])(\d+)$/)
      : null; // 5+4 yoki 5-4 formatidagi amal

    if (arithmeticOperation) {
      const updatedPaidDays = payments.map(payment => ({
        day: payment.day,
        date: payment.date,
        isPaid: payment.isPaid,
        price: payment.price,
        dailyStatus: payment.dailyStatus,
      }));

      try {
        await updatePaidDays({ _id: item._id, newPaidDays: updatedPaidDays })
        message.success("Davolanish kun muvaffaqiyatli yangilandi");
        setAdditionalPrice(null); // Reset the additional price
        setInputValue(null); // Reset the additional price
      } catch (error) {
        message.error("To'lovni yangilashda xato yuz berdi");
        console.error("Xato:", error);
      }

    } else {
      // To'lovlarni serverga jo'natish
      await updateRoomPayment({
        clientID: item.clientID,
        payments: payments // to'lovlar serverga jo'natilmoqda
      })
        .unwrap()
        .then((response) => {
          message.success(response.message);
          console.log("Yangilangan ma'lumot:", response.updatedRoom);
        })
        .catch((err) => {
          message.error("To'lovni yangilashda xato yuz berdi");
          console.error("Xato:", err);
        });

      // Agar amal bo'lmasa, to'lovni serverga jo'natishda oddiy miqdorni ishlatamiz
      await updateRoomStory({ id: item._id, body: clientPrice })
        .then((res) => {
          if (res?.data?.success) {
            setAdditionalPrice(null);
          } else {
            // Xatolik bo'lsa xabar
          }
        })
        .catch((err) => {
          console.log(err); // Xatolikni ushlash
        });


      // ------------------------------
      const allData = {
        clientID: item.clientMongooseId,
        price: additionalPrice,
        storyId: item._id,
        doctorIdNumber: item.doctorIdNumber,
      }
      console.log(allData);
      await createPaymentStory(allData)
        .then((res) => console.log(res))
        .catch((err) => console.log(err))

    }
  };

  // -------------------------------
  const getCardClassName = (isPaid, price) => {
    if (isPaid) return 'ant-card-green extro-colors';
    if (!isPaid && price > 0) return 'ant-card-yellow extro-colors';
    return 'ant-card-red extro-colors';
  };


  // -------------------------------
  // Funksiya to'lanmagan kunlarni va qarzni hisoblash uchun
  const calculateDebt = (roomData) => {
    const payForRoom = roomData.payForRoom; // Har bir kunning to'lov miqdori

    // Faqat to'lanmagan kunlarni olish
    const unpaidDays = roomData.paidDays?.filter(day => !day.isPaid);

    // Har bir to'lanmagan kun uchun qarzni hisoblash
    const totalDebt = unpaidDays.reduce((sum, day) => {
      const price = payForRoom - day.price; // Har bir kun uchun qarz miqdori
      return sum + price;
    }, 0);

    // Yangilangan qarzni saqlash
    return totalDebt;
  };


  const toggleIcon = (inx) => {
    setIsTopIconArray((prevState) => {
      const newArray = [...prevState];
      newArray[inx] = !newArray[inx]; // Berilgan elementni holatini o'zgartiramiz
      return newArray;
    });
  };

  return (
    <LayoutWrapper>
      <div className="updateRoom_wrapper">
        {roomData?.map((value, inx) => {
          return (
            <div key={inx} className="my-table">
              <div className="my-table-box my-table-box_main">
                {/* Tugma holatini o'zgartirish */}
                <div style={{ border: "none" }} className="my-table-box-items flex-items">
                  <div>

                    <Button
                      onClick={() => toggleIcon(inx)} // Har bir element uchun onClick
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      {isTopIconArray[inx] ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                  </div>
                </div>

                {/* Mijoz ismi */}
                <div className="my-table-box-items">
                  <span>Bemor</span>
                  <div>
                    {capitalizeFirstLetter(value?.clientFullname)}
                  </div>
                </div>

                {/* Telefon raqami */}
                <div className="my-table-box-items">
                  <span>Telefon raqami</span>
                  <div>


                    {"+998 " + PhoneNumberFormat(value?.clientPhone)}
                  </div>
                </div>

                {/* Boshlanish sanaqsisi */}
                <div className="my-table-box-items">
                  <span>Boshlanish sanasi</span>
                  <div>
                    {value.startDay}
                  </div>
                </div>

                {/* Davolanish kuni */}
                <div className="my-table-box-items">
                  <span>Davolanish kuni</span>
                  <div>
                    {value.paidDays?.length} kun
                  </div>
                </div>

                {/* Xona uchun to'lov */}
                <div className="my-table-box-items">
                  <span>Xona uchun to'lov</span>
                  <div>
                    {NumberFormat(value.clientPayForRoomPrice)} so'm
                  </div>
                </div>

                {/* Chiqish */}
                <div className="my-table-box-items flex-items">
                  <Button
                    style={buttonStyle}
                    onClick={() => {
                      OutInRoom(
                        value?.clientID,
                        room._id,
                        {
                          dayOfTreatment: CountingDay(
                            moment(value.startDay, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY")
                          ),
                          payForRoom: calculateRoomPayment(value),
                          id: value?._id,
                          outDay: todaysTime,
                        },
                        value
                      );
                    }}
                    button="true"
                    className="btn btn-primary"
                  >
                    <GiEntryDoor style={iconStyle} />
                  </Button>
                </div>

                {/* Xona tanlash */}
                <div className="my-table-box-items flex-items">
                  <Select
                    // style={{ width: "180px" }}
                    showSearch
                    className="InputForm"
                    placeholder="Xona tanlash"
                    optionFilterProp="children"
                    options={data}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    onChange={() => addUserToRoom()}
                  />
                </div>
              </div>


              {isTopIconArray[inx] && ( // Faqat shu elementning modalini ochamiz
                <div className="box-my_extro">
                  <div className="my-table-box my-table-box_extro">
                    {value?.paidDays?.map((item, i) => {
                      return (
                        <div key={i} className={getCardClassName(item?.isPaid, item?.price)}>
                          <p>{moment(item.date).format("DD.MM.YYYY")}</p>
                          <p>{NumberFormat(item.price)} so'm</p>
                        </div>
                      );
                    })}

                  </div>
                  <div className="extro-inp-box">
                    <input
                      type="text"
                      placeholder={`${NumberFormat(calculateDebt(value))} so'm`}
                      onChange={(e) => handleDebtPaymentChange(e, value)}
                    />
                    <Button disabled={isInputDisabled} type="primary" onClick={() => handlePayDebt(value)}>
                      <PlusOutlined />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )
        })}

        {roomModalState && (
          <RoomModal data={roomModalState} close={setRoomModalState} />
        )}
      </div>
    </LayoutWrapper>
  );
}

export default EnterRoom;






