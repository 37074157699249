import React, { useState, useRef } from "react";
import "./AppointmentSinglePage.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import { GiBodyHeight } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { LiaTemperatureHighSolid } from "react-icons/lia";
import { GiWeightScale } from "react-icons/gi";
import { BsDiagram3 } from "react-icons/bs";
import {
  PrinterOutlined,
  //  EyeOutlined
} from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { Button, Table, message, Popconfirm, Modal, Input, Form } from "antd";
import LayoutWrapper from "../../../components/layout/Layout";
import { setInfo } from "../../../redux/recordList/recordList";
import RecordList from "../../../components/checkLists/patientRecordList/RecordList";
import {
  useGetStoryByIdQuery,
  useGetStoriesQuery,
  useUpdateStoryMutation,
} from "../../../redux/clientStores";
import { useGetUsersQuery } from "../../../redux/clientApi";
import { useGetAllBotQuery } from "../../../redux/telegraf";
import { useUpdateDoctorSalaryMutation } from "../../../redux/doctorApi";
import { PhoneNumberFormat } from "../../../hook/NumberFormat";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
const { TextArea } = Input;

function AppointmentSinglePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const componentRef = useRef();
  let category = localStorage.getItem("doctorID");

  // RTK QUERIES
  const [updateStory] = useUpdateStoryMutation();
  const [updateDoctorSalary] = useUpdateDoctorSalaryMutation();
  const { data } = useGetStoryByIdQuery(id);
  const { data: dateInfo } = useGetAllBotQuery();
  const { data: allStory } = useGetStoriesQuery();
  let { data: dataMain } = useGetUsersQuery();

  let user = data?.innerData?.singleStory || {};
  let stories = allStory?.innerData;
  const userObject = dataMain?.data?.find((i) => i._id === user.clientID);
  const clinicObject = dateInfo?.find(
    (i) => i.clinicId === userObject?.clinicId
  );
  let DataStories = stories?.filter(
    (i) =>
      i.doctorIdNumber === category &&
      i.clientID === user.clientID &&
      i.view === true
  );

  const [sickname, setSickname] = useState("");
  const [retsept, setRetsept] = useState("");
  const [patientStatus, setPatientStatus] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [inputValues, setInputValues] = useState({
    firstInput: "",
    secondInput: "",
    thirdInput: "",
  });

  const checkID = (id) => dispatch(setInfo(id));
  function updateUserInfo(e) {
    e.preventDefault();

    if (!sickname || !retsept || !patientStatus) {
      return message.warning("Maydonlarni to'ldiring!");
    }

    let time = new Date();
    let todaysTime =
      time.getDate() + "." + (time.getMonth() + 1) + "." + time.getFullYear();

    let story = { ...user };

    story.view = true;
    story.writed_at = todaysTime;
    story.writed_doctor = localStorage.doctorName;
    story.patientStatus = patientStatus;
    story.retseptList = retsept;
    story.sickname = sickname;

    updateStory({ id: story?._id, body: story })
      .then((res) => {
        if (res?.data?.success) {
          return message.success(res?.data?.message);
        }
        message.warning(res?.error?.data?.message?.stringValue);
      })
      .catch((res) => console.log("err", res));

    updateDoctorSalary({
      salary: story.paySumm,
      doctor_id: story.doctorIdNumber,
      extraSpecialization:
        story.extraSpecialization === true ? story.doctorType : "",
    });

    // dispatch(setInfo(story));
    navigate("/appointments");
  }

  const Bmi = (weight, height) => {
    if (weight && height) {
      const heightInMeters = height / 100;
      const bmiValue = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      return bmiValue;
    }
  };

  const columns = [
    {
      title: "Sana",
      dataIndex: "sana",
      key: "sana",
    },
    {
      title: "Ism Familya",
      dataIndex: "ism",
      key: "ism",
    },
    {
      title: "Kasalik nomi",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  let dataHistory = [];
  if (DataStories) {
    dataHistory = Array.from(Object.values(DataStories), (item, i) => ({
      key: i,
      sana: item.day,
      ism: item.clientFullname,
      age: item.sickname,
      status: item.patientStatus,
      description: item.retseptList,
    }));
  }

  // const enterLoading = (index) => {};

  const handleOk = async () => {
    setModalVisible(false);

    const addStory = {
      ...user,
      weight: inputValues?.weight,
      height: inputValues?.height,
      temperature: inputValues?.temperature,
    };

    // Story ma'lumotini yangilash uchun serverga so'rov yuborish
    await updateStory(addStory)
      .then((res) => {
        if (res?.data?.success) {
          message.success("Ma'lumot muvaffaqiyatli yangilandi"); // Muvaffaqiyatli xabar chiqarish
        } else {
          message.warning("Serverda xatolik yuz berdi"); // Xatolik xabari
        }
      })
      .catch((err) => {
        console.error("Xatolik yuz berdi:", err);
        message.error(
          "So'rovingiz bajarilmadi, iltimos qaytadan urinib ko'ring."
        ); // So'rovingiz bajarilmagan xabari
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const info = {
    doctorFullname: user?.doctorFullname,
    clientPhone: user?.clientPhone,
    doctorType: user?.doctorType,
    day: user?.day,
    doctorPhone: user?.doctorPhone,
    retseptList: retsept,
    sickname,
    clientFullname: user?.clientFullname,
    birthday: user.birthday,
    address: {
      region: userObject?.address?.region,
      district: userObject?.address?.district,
      quarter: userObject?.address?.quarter,
    },
    // clinic data
    name: clinicObject?.name,
    address: clinicObject?.address,
    gmail: clinicObject?.gmail,
    phone: clinicObject?.phone,
  };

  return (
    <LayoutWrapper>
      <div className="appointmentSinglePage">
        <div className="appointmentSinglePage_info">
          <span>
            <span>
              <b>Bemor:</b>
              {/* <h3>{user?.clientFullname} </h3> */}
              <h3>
                {capitalizeFirstLetter(user?.clientFullname?.split(" ")[0]) +
                  " " +
                  capitalizeFirstLetter(
                    user?.clientFullname?.split(" ")[1]
                  )}{" "}
              </h3>
            </span>
            <span>
              <b>Telefon raqam</b>
              <h3>{PhoneNumberFormat(user?.clientPhone)}</h3>
            </span>
          </span>

          <div className="appoint-box-Container">
            <div className="appoint-box">
              <div className="box-bmi_Sing">
                <b>Tana Massa Indeksi:</b>
                {user?.weight && user?.weight ? (
                  <>
                    <span>
                      <div>
                        <GiWeightScale /> {user?.weight && user?.weight} kg
                      </div>
                      <div>
                        <GiBodyHeight /> {user?.height && user?.height} m
                      </div>
                    </span>
                    <span>
                      <div>
                        <LiaTemperatureHighSolid />{" "}
                        {user?.temperature && user?.temperature}
                      </div>
                      <div> BMI {Bmi(+user?.weight, +user?.height)}</div>
                    </span>
                  </>
                ) : (
                  <span className="BMI_none">
                    Topshirmagan{" "}
                    <Button
                      className="BMI_none-buuton"
                      onClick={() => setModalVisible(true)}
                    >
                      +
                    </Button>
                  </span>
                )}
              </div>

              {/* <div className="box-bmi_Sing">
                <b>Analiz</b>
                {true ? (
                  <Button
                    className="PoweroffOutlined"
                    type="primary"
                    onClick={() => enterLoading(1)}
                  >
                    <EyeOutlined />
                    Javoblarini ko'rish
                  </Button>
                ) : (
                  <span className="BMI_none"> Topshirmagan </span>
                )}
              </div> */}

              <div className="box-bmi_Sing">
                <b>Diagnostika</b>
                {user?.diagnostics ? (
                  <span>
                    <div>
                      <BsDiagram3 className="Diagram" /> {user?.diagnostics}
                    </div>
                  </span>
                ) : (
                  <span>O'tmagan</span>
                )}
              </div>

              {localStorage.category === "Kardiolog" && (
                <Link
                  className="toAmbulatorBtn"
                  to={`/ambulator/${user?.clientID}`}
                >
                  Ambulator Karta
                </Link>
              )}
            </div>
            {user?.extraTreatment?.length > 0 ? (
              <div className="appoint-box-bottom">
                <h3 className="treatment-subtitle">Tibbiy muolajalar</h3>
                <div className="appoint-box-bottom-main">
                  {user?.extraTreatment?.map((treatment, index) => (
                    <div key={index} className="treatment-item">
                      <h4>{treatment.name}</h4>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="extraInfo">
          <form>
            <label htmlFor="">Tashxis nomi*</label>
            <Input
              type="text"
              value={sickname}
              onChange={(e) => setSickname(e.target.value)}
              required
            />
            <label htmlFor="">Bemor holati*</label>
            <Input
              required
              type="text"
              value={patientStatus}
              onChange={(e) => setPatientStatus(e.target.value)}
            />
            <label htmlFor="" className="label">
              Retsept(dorilar)*
            </label>

            <TextArea
              showCount
              // maxLength={500}
              value={retsept}
              onChange={(e) => setRetsept(e.target.value)}
              placeholder="Retsept yozing..."
              style={{
                height: 100,
                resize: "none",
              }}
            />
            <div className="app-btns_print">
              {localStorage.category === "Kardiolog" ? (
                <Popconfirm
                  title="Ambulator kartani to'ldirdingizmi ?"
                  onConfirm={updateUserInfo}
                  okText="Ha"
                  cancelText="Yo'q"
                >
                  <button button="true" className="btn btn-secondary">
                    Saqlash
                  </button>
                </Popconfirm>
              ) : (
                <button
                  onClick={updateUserInfo}
                  button="true"
                  className="btn btn-secondary"
                >
                  Saqlash
                </button>
              )}
              <ReactToPrint
                trigger={() => (
                  <Button
                    className="appointmentSinglePage_PrintBtn"
                    onFocus={() => checkID(info)}
                  >
                    <PrinterOutlined /> Chop etish
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </form>
        </div>

        {dataHistory?.length ? (
          <>
            <h4 style={{ textAlign: "center" }}>Tarix</h4>
            <Table
              rowKey={"_id"}
              columns={columns}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => (
                  <>
                    <b style={{ textAlign: "center" }}>Dorilar ro'yxati</b>
                    <p
                      style={{
                        margin: 0,
                        textAlign: "center",
                      }}
                    >
                      {record.description}
                    </p>
                  </>
                ),
                rowExpandable: (record) => record.name !== "Not Expandable",
              }}
              dataSource={dataHistory}
            />
          </>
        ) : null}
      </div>
      <Modal
        title="BMI"
        open={modalVisible}
        onOk={handleOk}
        onCancel={() => setModalVisible(false)}
      >
        <Form layout="vertical">
          <Form.Item label="Birinchi Input">
            <Input name="height" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Vazni">
            <Input name="weight" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Tana harorati">
            <Input name="temperature" onChange={handleInputChange} />
          </Form.Item>
        </Form>
      </Modal>
      <div style={{ display: "none" }}>
        <RecordList componentRef={componentRef} />
      </div>
    </LayoutWrapper>
  );
}

export default AppointmentSinglePage;
