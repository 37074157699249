import Home from "../components/home/Home";
import Login from "../pages/Login";
import AddMain from "../pages/admin/addDoctors/main/AddMain";
import Patients from "../pages/receptionPage/patients/Main";
import Doctor from "../pages/receptionPage/doctor/Doctor";
import ReadMain from "../pages/admin/addDoctors/main/ReadMain";
import Rooms from "../pages/admin/rooms/Rooms";
import Support from "../pages/admin/support/Support";
import Cabins from "../pages/receptionPage/Cabins/Cabins";
import Register from "../pages/receptionPage/register/Registers";
import Appointments from "../pages/doctorPage/appointments/Appointments";
import AppointmentSinglePage from "../pages/doctorPage/appointment-single-page/AppointmentSinglePage";
import PatientsHistory from "../pages/doctorPage/patientsHistory/PatientsHistory";
import RecordList from "../components/checkLists/patientRecordList/RecordList";
import SinglePage from "../pages/admin/singlePage/SinglePage";
import GetPatients from "../pages/admin/singlePage/getPatients/GetPatients";
import Wallet from "../pages/doctorPage/wallet/Wallet";
import CabindEnter from "../pages/doctorPage/cabins/CabinsRoom";
import Analysis from "../pages/doctorPage/analysis/Analysis";
import PoteintsSlinglePage from "../components/poteintsSlinglePage/PoteintsSlinglePage";
import AdditionalTreatments from "../pages/doctorPage/additional-treatments/AdditionalTreatments";
import AnaliseAdditional from "../pages/doctorPage/additional-treatments/AnaliseAdditional";
import SinglePageDebtor from "../pages/receptionPage/patients/qarzdorlarBolimi/SinglePage";
import BiochemicalAnalysis from "../components/lists/analisList/AnalisList";
import SalaryDep from "../pages/admin/salaryDepartment/SalaryDep";
import Setting from "../pages/admin/setting/Setting";
import KunlikBemorlarTable from "../pages/admin/salaryDepartment/SinglePage";
import GetSinglePage from "../pages/receptionPage/doctor/SinglePage";
import FizioterapiyaList from "../components/lists/fizioterapiyaList/FizioterapiyaList";
import HomePage from "../pages/admin/home/HomePage";
import SinglePageNurse from "../pages/admin/addDoctors/nurses/SinglePageNurse";
import SingleNurse from "../pages/admin/salaryDepartment/SingleNurse";
import UpdateRoom from "../pages/receptionPage/Cabins/updateRoom/UpdateRoom";
import WorkersSinglePage from "../components/workersSinglePage/WorkersSinglePage";
import VisitLogIn from "../components/logInHistory/IogInHistory";
import Main from "../components/statistecs/Main";
import MedicalCalculators from "../components/medicalCalculators/Main";
import AmbulatorItem from "../components/lists/ambulatorList/Ambulator";
import Ambulatoriya from "../pages/doctorPage/ambulatoriya/Ambulatoriya";
import AmbulatoriyaSinglePage from "../pages/doctorPage/ambulatoriya/AmbulatoriyaSinglePage";
import DoctorFizioterapiyaPricesComponent from "../pages/doctorPage/DoctorFizioterapiyaPricesComponent";
import Nurse from "../pages/receptionPage/nurses/ReadNurses";
import MainVidomost from "../components/vidomostBlannk/MainVidomost";
import CashierReception from "../pages/kassir/Qabul";
import Expenses from "../pages/kassir/expenses/Expenses";
import CalCenterStory from "../pages/calCenter/calStory/CalCenterStory";
import PatentsCalCenter from "../pages/calCenter/patents/Patents";
import Debtors from "../pages/kassir/Debtors";
import EnterRoom from "../pages/receptionPage/Cabins/enterRoom/EnterRoom";
import DailyReport from "../pages/kassir/dailyReport/DailyReport";
import AddUserToRoom from "../pages/receptionPage/Cabins/updateRoom/addUserToRoom";

export const routes = [
  { path: "/", component: <Home /> },
  { path: "/login", component: <Login /> },
  { path: "/add-doctor", component: <AddMain /> },
  { path: "/patients", component: <Patients /> },
  { path: "reports/", component: <HomePage /> },
  { path: "/admin/doctors", component: <Doctor /> },
  { path: "/xodimlar", component: <ReadMain /> },
  { path: "/cabins", component: <Cabins /> },
  { path: "/receptionHome", component: <Register /> },
  { path: "/appointments", component: <Appointments /> },
  { path: "/kashelyok", component: <Wallet /> },
  { path: "/viewRoom", component: <Rooms /> },
  { path: "/cabins/:id", component: <EnterRoom /> },
  { path: "/support", component: <Support /> },
  { path: "/doctor-patients", component: <CabindEnter /> },
  { path: "/vidomost", component: <MainVidomost /> },
  { path: "/xisobotlar", component: <SalaryDep /> },
  { path: "/setting", component: <Setting /> },
  { path: "/setting", component: <Setting /> },
  { path: "/nurse", component: <Nurse /> },
  { path: "/doctor/patients_history", component: <PatientsHistory /> },
  { path: "/statistic", component: <Main /> },
  { path: "/medical-calculators", component: <MedicalCalculators /> },
  { path: "/additional-treatments", component: <AdditionalTreatments /> },
  { path: "/analis/additional", component: <AnaliseAdditional /> },
  {
    path: "/medical-fiziyo",
    component: <DoctorFizioterapiyaPricesComponent />,
  },
  { path: "/cashier-reception", component: <CashierReception /> },
  { path: "/expenses", component: <Expenses /> },
  { path: "/appointments_calCenter", component: <PatentsCalCenter /> },
  { path: "/calCenter_story", component: <CalCenterStory /> },
  { path: "/debtors", component: <Debtors /> },
  { path: "/daily-report", component: <DailyReport /> },

  //Components with ID's
  { path: "/appointments/:id", component: <AppointmentSinglePage /> },
  { path: "/analises/:_id", component: <Analysis /> },
  { path: "/AppointmentSinglePage/:id", component: <RecordList /> },
  { path: "/doctorSinglePage/:_id/:doctorType", component: <SinglePage /> },
  { path: "/biochemicalAnalysis/:_id", component: <BiochemicalAnalysis /> },
  { path: "/userDebtorSinglePage/:_id", component: <SinglePageDebtor /> },
  { path: "/fizioterapiyaAnalysisList/:_id", component: <FizioterapiyaList /> },
  { path: "/poteintsSlinglePage/:id", component: <PoteintsSlinglePage /> },
  { path: "/GetSinglePage/:_id/:doctorType", component: <GetSinglePage /> },
  {
    path: "/doctorSinglePageAdmin/:_id/:doctorType",
    component: <GetPatients />,
  },
  { path: "/kunlikBemorlar/:_id", component: <KunlikBemorlarTable /> },
  { path: "/singlePageNurse/:_id", component: <SinglePageNurse /> },
  { path: "/singleNurseSalary/:_id", component: <SingleNurse /> },
  { path: "/updateRoom/:_id", component: <UpdateRoom /> },
  { path: "/workersSinglePage/:id", component: <WorkersSinglePage /> },
  { path: "/visitLogIn/:id", component: <VisitLogIn /> },
  { path: "/ambulator/:idNumber", component: <AmbulatorItem /> },
  { path: "/ambulatoriya", component: <Ambulatoriya /> },
  { path: "/ambulatoriya-signle/:id", component: <AmbulatoriyaSinglePage /> },
  { path: "/addUseToRoom/:id/:room", component: <AddUserToRoom /> },
];
